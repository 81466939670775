import React from "react";

import "./styles.css";

export const CardItem = ({ card, svg }) => {
  return (
    <div className="card">
      {svg && <div className="card__decor">{svg}</div>}
      <div className="card__icon_wrap">
        <img src={card.icon?.sourceUrl} alt={card.icon?.altText || "icon"} />
      </div>
      <h4>{card.cardTitle}</h4>
      <div className="card__description">{card.description}</div>
    </div>
  );
};
