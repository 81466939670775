import React from "react";
import PanoramaSlider from "./slider-panorama";
import { useInView } from "react-intersection-observer";
import BounceAnimation from "../../../BounceAnimation";

import GearBig from "../../../../images/svg/new-svg/gear_big.svg";
import GearSmall from "../../../../images/svg/new-svg/gear_sm.svg";
import "./style.css";

const Images = [
  { url: "https://www.7fridays.net/static/cc0814c09922ebd640ffa1ecb042bc6b/38b45/velartez.webp" },
  { url: "https://www.7fridays.net/static/05137d55fc3333e1730098623b7f8ca4/38b45/qube.webp" },
  { url: "https://www.7fridays.net/static/320c4055b5fd075dc3ab32d4d4699077/e0445/netsecurity.webp" },
  { url: "https://www.7fridays.net/static/422b932607a05bd638005b6f48d7cd96/e0445/merchone.webp" },
  { url: "https://www.7fridays.net/static/3119e7fb19319f0795a2613900591651/e0445/hr.university.webp" },
  { url: "https://www.7fridays.net/static/08ca0e4c72f2854f2d691b1381d2b39a/e0445/DOIT.webp" },
  { url: "https://www.7fridays.net/static/b6d3391f8676b71755a71e93716d21ad/38b45/zoog.webp" },
  { url: "https://www.7fridays.net/static/02a3c5c33e0216f7907cc8198644a736/e0445/AVLS.webp" },
  { url: "https://www.7fridays.net/static/cc0814c09922ebd640ffa1ecb042bc6b/38b45/velartez.webp" },
];

const WorkSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.15,
  });

  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.15,
  });

  return (
    <section className="work">
      <h2>
        <BounceAnimation text="Our Work" duration={600} direction="top-to-bottom" isInView={inView} />
      </h2>
      <div className={"slider-area"} ref={ref}>
        <div className={"slider-wrapper " + (inView1 ? "visible" : null)} ref={ref1}>
          <PanoramaSlider slides={Images.map((item) => item.url)} />
        </div>
      </div>
      <div className="positioned-area">
        <div className="gears">
          <div className="big-gear">
            <GearBig />
          </div>
          <div className="small-gear">
            <GearSmall />
          </div>
        </div>
        <article>
          <p>
            Curious about what we can build for you?
          </p>
          <p>
            {"Stop watching and let's discuss your next great website together"}
          </p>
        </article>
      </div>
    </section>
  );
};

export default WorkSection;
