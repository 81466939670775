import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import "./menu.css";

const HeaderMenu = (visibility) => {
  const { links } = useStaticQuery(graphql`
    query MenuHeaderNew {
      links: allWpMenuItem(filter: { locations: { eq: HEADER_MENU } }) {
        edges {
          node {
            label
            uri
          }
        }
      }
    }
  `);

  return (
    <div className={"header_menu " + visibility.menuVisibility}>
      <ul>
        {links.edges.map(({ node }, index) => (
          <li
            className="menu-item"
            key={node.uri}
            style={{ "--d": index * 100 + 500 + "ms" }}
          >
            <Link
              activeClassName="menu-item-active"
              to={node.uri}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = node.uri;
              }}
            >
              {node.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderMenu;
