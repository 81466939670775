import React, { useState } from "react";
import SvgBgSubscribe from "../../images/svg/bg-subscribe.svg";
import SvgTriangles from "../../images/svg/triangles.svg";
import SvgArrowSubscribe from "../../images/svg/arrow-subscribe.svg";
import Fields from "src/components/fields";
import "./style.css";
import TheIcon from "src/components/the-icon";
import { useForm } from "react-hook-form";
import axios from "axios";
import { formData } from "src/util/helpers";

const SubscribeForm = ({ formId = 3570 }) => {
  const [isSendForm, setSendForm] = useState({
    message: "",
    status: null,
  });

  const resetErrorForm = () => {
    setSendForm({
      message: "",
      status: null,
    });
  };

  const { handleSubmit, control } = useForm();
  const onSubmit = async (payload) => {
    try {
      resetErrorForm();
      const { data } = await axios({
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        url: process.env.GATSBY_API_CONTANT_FORMS.replace("{form-id}", formId),
        data: formData(payload),
      });

      if (typeof window?.gtag === "function") {
        window.gtag("event", "Send Form", {
          event_category: "Form",
          event_label: Number(formId) === 3205 ? "Form client" : "Form career",
        });
      }

      setSendForm({ status: data.status, message: data.message });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <section className="subscribe-section">
        <SvgTriangles className="triangles"></SvgTriangles>
        <div className="bg">
          <SvgBgSubscribe></SvgBgSubscribe>
        </div>
        <div className="container">
          <div className="intro">
            <div className="left-box">
              <h2>SUBSCRIBE</h2>
              <div className="text">
                subscribe to our newsletter to be updated on our latest news in
                the world of web development
              </div>
            </div>
            <div className="rigtht-box">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Fields.Input
                  name="your-email"
                  icon={<TheIcon.Mail />}
                  rules={{
                    required: "The field is required.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  }}
                  placeholder="Mail"
                  control={control}
                >
                  <button type="submit">
                    <SvgArrowSubscribe></SvgArrowSubscribe>
                  </button>
                </Fields.Input>

                <span className={isSendForm.status}>{isSendForm.message}</span>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubscribeForm;
