import React, { useRef, useEffect, useState } from "react";
import { RandomReveal } from "react-random-reveal";

import Button from "../../../cta-button";
//import { Link } from "gatsby";
import mainBammerCanvasAnim from "./script";

//import LinkedinIcon from "../../../../images/svg/new-svg/linkedin.svg";
//import SocialIcon from "../../../../images/svg/new-svg/social-icon.svg";
import PlanetIcon from "./planet.svg";

import "./style.css";

const MainBanner = ({openModal}) => {
  const circles = 3;
  const radius = 30;
  const [itemsPositions, setItemsPositions] = useState([
    { x: 0, y: 0 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
  ]);
  const floatingTime = [1800, 2500, 1300];

  const updatePositions = () => {
    let newPositions = [];
    for (let i = 0; i < circles; i++) {
      const x = Math.random() * (2 * radius) - radius;
      const y = Math.random() * (2 * radius) - radius;
      newPositions.push({ x, y });
    }
    setItemsPositions(newPositions);
  };

  useEffect(() => {
    if (window.innerWidth < 600)
      setTimeout(() => {
        refCanvas.current.classList.add("mob");
      }, 0);

    for (let i = 0; i < circles; i++) {
      const intervalId = setInterval(() => {
        updatePositions();
      }, floatingTime[i]); // Adjust the interval as needed

      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  const refCanvas = useRef(null);
  const characterSet = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    "_",
    "+",
    "=",
    "-",
  ];
  useEffect(() => {
    mainBammerCanvasAnim(refCanvas.current, PlanetIcon);
  }, []);
  return (
    <section className="main_banner">
      <div className="banner-inner">
        <div
          className="banner-circle banner-circle-1"
          style={{
            transform: `translate(${itemsPositions[0].x}px, ${itemsPositions[0].y}px)`,
            transition: `${floatingTime[0] + 1000}ms ease`,
          }}
        ></div>
        <div
          className="banner-circle banner-circle-2"
          style={{
            transform: `translate(${itemsPositions[1].x}px, ${itemsPositions[1].y}px)`,
            transition: `${floatingTime[1] + 1000}ms ease`,
          }}
        ></div>
        <div
          className="banner-circle banner-circle-3"
          style={{
            transform: `translate(${itemsPositions[2].x}px, ${itemsPositions[2].y}px)`,
            transition: `${floatingTime[2] + 1000}ms ease`,
          }}
        ></div>
        <div className="banner-circle-gradient banner-circle-gradient-1"></div>
        <div className="banner-circle-gradient banner-circle-gradient-2"></div>
        <div className="banner-circle-gradient banner-circle-gradient-3"></div>

        <div className="green-circle green-circle-1">
          <canvas ref={refCanvas}></canvas>
        </div>
        <div className="green-circle green-circle-2"></div>
        <div className="green-circle green-circle-3"></div>

        <div className="container">
          <article>
            <h1>
              <span>
                <RandomReveal isPlaying duration={3} characters="World" characterSet={characterSet} />
              </span>
              <span>
                <RandomReveal isPlaying duration={2.5} characters="Best" characterSet={characterSet} />
              </span>
              <span>
                <RandomReveal isPlaying duration={3.5} characters="Services" characterSet={characterSet} />
              </span>
            </h1>
            <div className="content-part">
              <p>
                Unlock the full potential of your business with our cutting-edge services that have won over hundreds of startups and established companies alike. Embrace innovation and elevate your business to new heights!{" "}
              </p>
              <div className="btn_box" id="meet_us">
                <Button type="animate" onClick={openModal}>Meet Us</Button>
              </div>
            </div>
          </article>
        </div>
        <div className="banner-social">
          {/*<Link to="https://www.linkedin.com/company/7fridays-inc/" target="_blank">
            <LinkedinIcon />
          </Link>*/}
          {/*<Link to="#" target="_blank">
            <SocialIcon />
          </Link>*/}
        </div>
      </div>
    </section>
  );
};

export default MainBanner;
