import React from "react";
import ArrowPagination from "../../images/svg/arrow-right.svg";
import PaginationJs from "react-js-pagination";
import {navigate} from 'gatsby';
import "./style.css";


const Pagination = ({ currentPage, totalPage, postsPerPage }) => {
  const handlePageClick = async (page) => {
    const routeTo = page === 1 ? "/blog" : `/blog/${page}`;
    await navigate(routeTo);
  };
  return (
    <section className="pagination-wrapper">
      <div className="container">
        {totalPage > 1 ? (
          <PaginationJs
            nextPageText={<ArrowPagination />}
            hideFirstLastPages={true}
            prevPageText={<ArrowPagination style={{ transform: "rotate(180deg)" }} />}
            innerClass="wrap"
            itemClass="list"
            onChange={handlePageClick}
            activeClass="active"
            itemsCountPerPage={postsPerPage}
            totalItemsCount={Math.ceil(postsPerPage * totalPage)}
            activePage={currentPage}
          />
        ) : null}
      </div>
    </section>
  );
};

export default Pagination;
