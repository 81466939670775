// See .cache/page-templates after running dev/build
// to understand how this file ends up looking

import React from "react";
import { graphql } from "gatsby";
import { useContext } from "react";
import Seo, { SEOContext } from "gatsby-plugin-wpgraphql-seo";

import BannerSection from '../../src/components/page/BannerSection'
import CountersSection from '../../src/components/page/CountersSection'
import EmailCallSection from '../../src/components/page/EmailCallSection'
import TeamSection from '../../src/components/page/TeamSection'
import TwoImageSection from '../../src/components/page/TwoImageSection'

const PageTemplate = (pageProps) => {
  const data = useContext(SEOContext);
  let components;

    components = pageProps.data.wpPage['flexible']['mainFlexibleContent'] || []
  
  components = components.map((component) => {
    return {
      name: component.__typename.split("_").pop(),
      data: component,
    };
  });

  console.log("TEST");
  return (
    <>
      <Seo post={Object.assign(data, pageProps.data.wpPage)} />
      {components.map((component, index) => {

      if (component.name == 'BannerSection') {
        return <BannerSection {...component.data} key={index} />
      }
    

      if (component.name == 'CountersSection') {
        return <CountersSection {...component.data} key={index} />
      }
    

      if (component.name == 'EmailCallSection') {
        return <EmailCallSection {...component.data} key={index} />
      }
    

      if (component.name == 'TeamSection') {
        return <TeamSection {...component.data} key={index} />
      }
    

      if (component.name == 'TwoImageSection') {
        return <TwoImageSection {...component.data} key={index} />
      }
    
        return (
          <div key={index}>
            Error: The component {component.name} was not found
          </div>
        );
      })}
    </>
  );
};

export default PageTemplate;


    export const query = graphql`
      query PageQuery21($id: String!) {
        wpPage(id: {eq: $id}) {
          nodeType
          title
          uri
          seo {
            breadcrumbs {
              text
              url
            }
            canonical
            cornerstone
            focuskw
            fullHead
            metaDesc
            metaKeywords
            metaRobotsNofollow
            metaRobotsNoindex
            opengraphDescription
            opengraphAuthor
            opengraphImage {
              altText
              sourceUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
            readingTime
            schema {
              articleType
              pageType
              raw
            }
            title
            twitterDescription
            twitterTitle
        }
          
    flexible {
      mainFlexibleContent {
        __typename
         
 
      ... on WpPage_Flexible_MainFlexibleContent_BannerSection {
        ...Page_BannerSection
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_CountersSection {
        ...Page_CountersSection
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_EmailCallSection {
        ...Page_EmailCallSection
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_TeamSection {
        ...Page_TeamSection
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_TwoImageSection {
        ...Page_TwoImageSection
      }
    
      }
    }
  
        }
      }
    `
  
