import React from "react";
import { getSrc, StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import "./style.css";
const WorkWithSection = (props) => {
  return (
    <section className="work_with_section ">
      <div className="work_main">
        <div className="big_img_wrapp">
          <img
            className="work_big_img"
            src={getSrc(props.bigImage.localFile)}
            alt={props.bigImage.altText}
          />
          <StaticImage
            className="work_triangle"
            src="../../../images/triangle-90.png"
            alt="bg"
          />
        </div>
        <div className="container">
          <div className="work_text_part_wrapp">
            <h2>{parse(props.sectionTitle)}</h2>
            <p>{parse(props.sectionText)}</p>
            <div className="work_repeater_box">
              {props?.repeater?.map((item, index) => (
                <div className="box" key={index}>
                  <h3>
                    <span>{parse(item.rTitle)}</span>
                    <span className="r_title_line"></span>
                  </h3>
                  <div>
                    {parse(item.rText)}
                    <StaticImage
                      lazy="eager"
                      className="work_repeater_box__image"
                      src="../../../images/check-88.png"
                      alt="check"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WorkWithSection;
