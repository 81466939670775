import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { formData } from "src/util/helpers";
import { useForm } from "react-hook-form";
import Fields from "src/components/fields";
import TheIcon from "src/components/the-icon";
import "/src/styles/fields.scss";

const ContactForm = ({ formId }) => {
    const [isSendForm, setSendForm] = useState({
        message: "",
        status: null,
    });
    const { handleSubmit, control } = useForm();
    const onSubmit = async (payload) => {
        try {
            const { data } = await axios({
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                url: process.env.GATSBY_API_CONTANT_FORMS.replace("{form-id}", formId),
                data: formData(payload),
            });

            if (typeof window?.gtag === "function") {
                window.gtag("event", "Send Form", {
                    event_category: "Form",
                    event_label: Number(formId) === 3205 ? 'Form client' : 'Form career',
                });
            }

            setSendForm({ status: data.status, message: data.message });
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="hf_form_box"
            style={{ display: "flex", flexDirection: "column" }}
        >
            <div className="fields">
                <Fields.Input
                    name="your-name"
                    rules={{ required: "The field is required." }}
                    icon={<TheIcon.User />}
                    placeholder="Your Name"
                    control={control}
                />
                <div className="fields__row-2">
                    <Fields.Input
                        name="tel-951"
                        icon={<TheIcon.Phone />}
                        placeholder="Phone Number"
                        control={control}
                    />
                    <Fields.Input
                        name="your-email"
                        icon={<TheIcon.Mail />}
                        rules={{
                            required: "The field is required.",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address",
                            },
                        }}
                        placeholder="Mail"
                        control={control}
                        type="email"
                    />
                </div>
                <Fields.Textarea
                    name="your-message"
                    cols={40}
                    rules={{ required: "The field is required." }}
                    icon={<TheIcon.Bubble />}
                    placeholder="Message"
                    control={control}
                />

                <div className="upload_wrapp">
                    <span className="upload_res">Upload Resume</span>
                    <div className="upload_label" >
                        <Fields.Input
                            name="file-218"
                            control={control}
                            type="file"
                        />
                    </div>
                </div>

                <div className="standart_green_btn">
                    <input
                        type="submit"
                        value="Submit"
                        className="wpcf7-form-control has-spinner wpcf7-submit"
                    />
                    <span className="wpcf7-spinner"></span>
                </div>
                {isSendForm.status ? (
                    <span className={isSendForm.status}>{isSendForm.message}</span>
                ) : null}
            </div>
        </form>
    );
};

ContactForm.propTypes = {
    formId: PropTypes.number.isRequired,
};

export default ContactForm;
