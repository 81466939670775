import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

// import "./style.css";

const CustomCursor = () => {
  const cursorPos = { x: 0, y: 0 };
  const cursorBorderPos = { x: 0, y: 0 };
  const easting = 8;

  function cursorMove(event) {
    const cursor = document.querySelector("#cursor");

    if (cursor) {
      cursorPos.x = event.clientX;
      cursorPos.y = event.clientY;
      cursor.style.transform = `translate(${cursorPos.x}px, calc(${cursorPos.y}px + var(--posY))`;
    }
  }

  function cursorBorderUpdate() {
    const cursorBorder = document.querySelector("#cursor-border");

    if (cursorBorder) {
      cursorBorderPos.x += (cursorPos.x - cursorBorderPos.x) / easting;
      cursorBorderPos.y += (cursorPos.y - cursorBorderPos.y) / easting;

      cursorBorder.style.transform = `translate(${cursorBorderPos.x}px, calc(${cursorBorderPos.y}px + var(--posY))`;

      window.requestAnimationFrame(cursorBorderUpdate);
    }
  }

  useEffect(() => {
    window.addEventListener("mousemove", cursorMove);
    return () => {
      window.removeEventListener("mousemove", cursorMove);
    };
  }, [cursorPos, cursorBorderPos]);

  useEffect(() => {
    cursorBorderUpdate();
  }, []);

  return (
    <>
      <Helmet>
        <style>
          {`{/** {
  cursor: none !important;
}*/}

#cursor {
  position: fixed;
  top: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  background-color: #2cc486;
  border-radius: 50%;
  pointer-events: none;
  z-index: 999;
}

#cursor-border {
  --size: 50px;
  position: fixed;
  top: calc(var(--size) / -2);
  left: calc(var(--size) / -2);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 1px solid #e5eaee;
  pointer-events: none;
  transition: top 0.15s ease-out, left 0.15s ease-out, width 0.15s ease-out, height 0.15s ease-out,
    background-color 0.15s ease-out;
  z-index: 999;
  mix-blend-mode: difference;
}
@media (max-width: 1199px) {
  * {
    cursor: auto !important;
  }

  #cursor,
  #cursor-border {
    display: none !important;
  }
}`}
        </style>
      </Helmet>
      <div id="cursor"></div>
      <div id="cursor-border"></div>
    </>
  );
};

export default CustomCursor;
