import React, { lazy } from "react";
import { Link } from "gatsby";
import parse from "html-react-parser";
import useOpenContactModal from "src/hooks/useOpenContactModal";
import useSwiperLazy from 'src/hooks/useSwiperLazy';
import { InView } from "react-intersection-observer";
const SliderImage = lazy(() =>
  import("src/components/page/BestWorkSection/slider-image")
);

import "./style.css";
const BestWorkSection = (props) => {
  const [isLoadSwiper, loadSwiper] = useSwiperLazy();
  const [_, toggleModal] = useOpenContactModal();

  const openModal = (e) => {
    e.preventDefault();
    toggleModal(true);
  };

  return (
    <InView
      onChange={loadSwiper}
      triggerOnce={true}
      threshold={0}
      rootMargin="25%"
    >
      <section className="best_work_section">
        <div className="container">
          <div className="text_wrapp">
            <h2>{parse(props.sectionTitle)}</h2>
            <p>{parse(props.sectionText)}</p>
          </div>
        </div>
        {Array.isArray(props.workSlider) && isLoadSwiper ? (
          <SliderImage images={props.workSlider} />
        ) : null}
        {props.workBtn ? (
          <Link className="work_btn standart_green_btn" to={props.workBtn.url}>
            <span>{props.workBtn.title}</span>
          </Link>
        ) : (
          <a
            className="work_btn standart_green_btn open_modal"
            href="#"
            onClick={openModal}
          >
            <span>Contact Us</span>
          </a>
        )}
      </section>
    </InView>
  );
};
export default BestWorkSection;
