import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import parse from "html-react-parser";

import WaveBgSvg from "../../../images/svg/wave.svg";
import CallUsSvg from '../../../images/svg/call-us.svg';
import EmailUsSvg from '../../../images/svg/email-us.svg';
import "./style.css";

const EmailCallSection = (props) => {
  const {
    wpPage: { contactSite },
  } = useStaticQuery(graphql`
    query FrontPageContact {
      wpPage(isFrontPage: { eq: true }) {
        contactSite {
          companyEmail
          telNumber
        }
      }
    }
  `);
  return (
    <section className="email_call_section">
      <div className="email_call_content">
        <h2>{parse(props.sectionTitle)}</h2>
        <p className="email_call_subtitle">{parse(props.sectionText)}</p>
        <div className="contact_btns_wrapp">
          <div className="btn_inner">
            <a href={`mailto:${contactSite.companyEmail}`}>
              <div className="btn_img">
                <EmailUsSvg />
              </div>
              <p>EMAIL US</p>
            </a>
          </div>
          <div className="btn_select">
            <p>or</p>
          </div>
          <div className="btn_inner">
            <a href={`tel:${contactSite.telNumber}`}>
              <div className="btn_img">
              <CallUsSvg />
              </div>
              <p>CALL US</p>
            </a>
          </div>
        </div>
        <div className="email_call_bg">
          <WaveBgSvg />
        </div>
      </div>
    </section>
  );
};

export default EmailCallSection;
