import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const LogoItem = ({ item }) => {
  const image = getImage(item?.companyLogo?.localFile);

  return (
    <Link
      to={item?.companyLink?.url || "#"}
      aria-label={item?.companyLink?.title || "Company logo link"}
      target={item?.companyLink?.target || "_self"}
    >
      <div className="img_wrap">
        {image ? (
          <GatsbyImage
            className="test"
            alt={item?.companyLogo?.altText || "Company logo"}
            image={image}
            loading="eager"
            imgClassName="logo_img"
          />
        ) : null}
      </div>
    </Link>
  );
};

export default LogoItem;
