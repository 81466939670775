import React from "react";
import {graphql, useStaticQuery, Link, navigate} from "gatsby";
const SolutionLinks = () => {
  const {
    frontPage: { footerSolutionLinks },
  } = useStaticQuery(graphql`
    query MyQuery {
      frontPage: wpPage(isFrontPage: { eq: true }) {
        footerSolutionLinks {
          footerRepeater {
            footerSolutionLink {
              title
              url
              target
            }
          }
          fieldGroupName
        }
      }
    }
  `);



  const refreshPage = (e)=>{
    e.preventDefault();
    let url = e.target.getAttribute('href');
    if (window.location.pathname === '/our-solutions/') {
        navigate(url);
        var topPos = document.querySelector('.scroll_banner_section').scrollHeight + 100;
        setTimeout(function () {
            window.scrollTo({top: topPos, left: 0, behavior: 'smooth'});
        } , 20)

    } else {
        window.location = url;
    }
  }



  return (
    <ul>

      {footerSolutionLinks.footerRepeater.map(({ footerSolutionLink }) => (
        <li key={footerSolutionLink.url}>
            <Link
              to={'/our-solutions/'+footerSolutionLink.url}
              onClick={refreshPage}
              state={{ tabOpen: footerSolutionLink.url.replace('#', '')  , lox: footerSolutionLink.url.replace('#', '' )}}

          >
            {footerSolutionLink.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default SolutionLinks;
