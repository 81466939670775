import React from "react";
import ItemsList from "./itemsList";
import MarqueeSlider from "./slider/slider";
import { useInView } from "react-intersection-observer";
import BounceAnimation from "../../../BounceAnimation";

import "./style.css";

const TestSliderList = [
  { title: "Responsive Design" },
  { title: "Framework" },
  { title: "Database" },
  { title: "JavaScript" },
  { title: "Version Control" },
  { title: "Wireframe" },
  { title: "Scalability" },
  { title: "Hosting" },
  { title: "User Interface" },
  { title: "Microservices" },
  { title: "DevOps" },
];
const InformationSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <section className="info-section">
      <div className="container">
        <div className="flex-container" ref={ref}>
          <h2 className={inView ? "visible" : ""}>
            <span className="line"></span>
            <BounceAnimation text="Technology" duration={1000} direction="bottom-to-top" isInView={inView} />
            <BounceAnimation text="Edge" duration={1000} direction="bottom-to-top" isInView={inView} />
            <BounceAnimation text="Decisions" duration={1000} direction="bottom-to-top" isInView={inView} />
          </h2>
          <article>
            <h3>Technology Partner for your business grow</h3>
            <p>
              Empower your business with a technology partner dedicated to your success. Illuminate your path to success by strategically aligning long-term goals with current needs and resources.
              Your future begins with a dynamic technology partnership!
            </p>
          </article>
        </div>
        <ItemsList />
      </div>
      <MarqueeSlider slides={TestSliderList.map((item) => item.title)} />
    </section>
  );
};

export default InformationSection;
