import React from "react";

import SubTitle from "../../subtitle";
import { CardList } from "../../cards/card-list";
import DotsSvg from "../../../images/svg/group_dots.svg";
import DotsReverseSvg from "../../../images/svg/group_dots_reverse.svg";

import "./styles.css";

const BusinessCardsWithBg = (props) => {
  const { subtitle, subtitleBgColor, sectionBgColor, cards } = props;

  return (
    <section
      className="business_cards_with_bg"
      style={{ backgroundColor: sectionBgColor }}
    >
      <div className="dots_wrap_top">
        <DotsSvg />
      </div>
      <div className="container">
        {subtitle && <SubTitle title={subtitle} Bg={subtitleBgColor} />}
        {cards && <CardList cards={cards} svg={false} />}
      </div>
      <div className="dots_wrap_bottom">
        <DotsReverseSvg />
      </div>
    </section>
  );
};

export default BusinessCardsWithBg;
