import React from "react";
import parse from "html-react-parser";
import ContactFormFile from "src/components/form/contact-form-file";

import WaveSvg from "src/images/svg/wave.svg";
import "./style.css";

const CareerFormSection = (props) => {
  return (
    <section className="career_form_section">
      <div className="container">
        <h2>{parse(props.sectionTitle)}</h2>
        <div className="career_form_box form_wrapp">
          <ContactFormFile formId={3249} />
        </div>
        <div className="career_bg">
          <WaveSvg />
        </div>
      </div>
    </section>
  );
};

export default CareerFormSection;
