import React, { useState } from "react";
import parse from "html-react-parser";
import classNames from "classnames";

import AnimateHeight from "react-animate-height";
import ListArrowSvg from "src/images/svg/list_arrow.svg";
const CareerDropDown = ({ text, title }) => {
  const [isOpenTab, setOpenTab] = useState(false);

  const toggleTab = () => setOpenTab(!isOpenTab);
  return (
    <li className={classNames({ active: isOpenTab })}>
      <h4 onClick={toggleTab}>
        {title ? <span className="career_title">{parse(title)}</span> : null}
        <div className="line"></div>
        <div className="plus_box">
          <div className="plus"></div>
          <div className="minus"></div>
        </div>
        <ListArrowSvg />
      </h4>
      <AnimateHeight duration={500} height={isOpenTab ? "auto" : 0}>
        <article>
          <p>{parse(text)}</p>
        </article>
      </AnimateHeight>
    </li>
  );
};

export default CareerDropDown;
