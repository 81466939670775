import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import LogoItem from "./logo-item";

const BlockContent = ({ items }) => {
  const hasLogos = items.some((item) => item.logoOnoff === "On");

  return (
    <>
      {items.map((item, index) => (
        <div className="item" key={"solution" + index}>
          <div className={`container ${hasLogos ? "has-logos" : ""}`}>
            <div className="img">
              <GatsbyImage
                alt={item.imageSite?.altText}
                image={getImage(item.imageSite?.localFile)}
                loading="eager"
                imgClassName="team_image"
              />
            </div>
            <div className="txt-wrap">
              <h2>{item.titleSite}</h2>
              <div className="desc">{item.descriptionSite}</div>
              {item.logoOnoff === "On" && item.logoList && (
                <div className="logo_list">
                  {item?.logoList?.map((logo, idx) => {
                    return (
                      logo?.companyLogo?.localFile?.childImageSharp && (
                        <div className="logo_wrap" key={"logo" + idx}>
                          <LogoItem item={logo} />
                        </div>
                      )
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default BlockContent;
