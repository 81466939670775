import React from "react";
import Marquee from "react-fast-marquee";

import "./slider-style.css";

const MarqueeSlider = ({ slides }) => {
  const textSpeed = 50;

  return (
    <div className="marquee_section">
      <div className="marquee-section-inner">
        <Marquee speed={textSpeed}>
          {slides.map((slide, id) => (
            <h3 key={id}>
              <span>{slide}</span>
            </h3>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default MarqueeSlider;
