import React from "react";
import BlockContent from "./additionalComponents/block-content";

import "./styles.css";

const SolutionBlocks = (props) => {
  const { webSites } = props;

  return (
    <section className="solution_blocks">
      <div className="container-wrap">
        <div className="tab_content_wrapp">
          {webSites && <BlockContent items={webSites} />}
        </div>
      </div>
    </section>
  );
};

export default SolutionBlocks;
