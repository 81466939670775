import React from "react";
import { Link } from "gatsby";

import './style.css';

const BreadcrumbsRouter = ({homeLink , title}) => {
    return (
        <div className="breadcrumbs-wrapper">
            <div className="home-link"><Link to={homeLink}>Home <span> &#8250;</span></Link></div>
            <div className="post-name">{title}</div>
        </div>
    )
}

export default BreadcrumbsRouter;