import React, { useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "src/components/form/contact-form";

import TheEmail from "src/components/the-email";
import CloseSvg from "src/images/svg/modal-close.svg";
import "./style.css";

const ContactModalNew = ({ eventClose }) => {
    const closeModal = () => {
        eventClose(false);
    };
    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                eventClose();
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    return (
        <div
            style={{position: 'absolute'}}
            id="contact_modal"
            className="modal_window open"
        >
            <div
                className="close_area"
                onClick={closeModal}
                onKeyDown={closeModal}
            ></div>
            <div className="hf_main_wrapp">
                <div className="hf_inner_wrapp">
                    <div className="hf_close_btn">
                        <div className="hf_close_btn_inner" onClick={closeModal}>
                            <p>Close</p>
                            <CloseSvg />
                        </div>
                    </div>
                    <div className="hf_logo">
                        <StaticImage
                            width={230}
                            height={50}
                            alt="logo"
                            src="../../../images/footer_logo276.png"
                        />
                    </div>
                    <ContactForm formId={3205} />
                    <div className="hf_bottom_contact">
                        <p>Contact</p>
                        <div className="hf_email_phone">
                            <div className="hf_email">
                                <TheEmail />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactModalNew;
