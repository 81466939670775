import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { formData } from "src/util/helpers";
import { useForm } from "react-hook-form";
import Fields from "src/components/fields";
import TheIcon from "src/components/the-icon";
import "./style.css";
//import Button from "../cta-button";

const FutureForm = ({ formId }) => {
    const [isSendForm, setSendForm] = useState({
        message: "",
        status: null,
    });
    const { handleSubmit, control } = useForm();
    const onSubmit = async (payload) => {
        try {
            const { data } = await axios({
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                url: process.env.GATSBY_API_CONTANT_FORMS.replace("{form-id}", formId),
                data: formData(payload),
            });

            if (typeof window?.gtag === "function") {
                window.gtag("event", "Send Form", {
                    event_category: "Form",
                    event_label: Number(formId) === 3205 ? 'Form client' : 'Form Future',
                });
            }

            setSendForm({ status: data.status, message: data.message });
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="hf_form_box"
            style={{ display: "flex", flexDirection: "column" }}
        >
            <div className="fields">
                <Fields.Input
                    name="your-name"
                    rules={{ required: "The field is required." }}
                    icon={<TheIcon.User />}
                    placeholder="Your Name*"
                    control={control}
                />
                <div className="fields__row">
                    <Fields.Input
                        name="your-email"
                        icon={<TheIcon.Mail />}
                        rules={{
                            required: "The field is required.",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address",
                            },
                        }}
                        placeholder="Mail*"
                        control={control}
                        type="email"
                    />
                </div>
                <Fields.Textarea
                    name="your-message"
                    cols={40}
                    rules={{ required: "The field is required." }}
                    icon={<TheIcon.Bubble />}
                    placeholder="Message*"
                    control={control}
                />

                <div className="standart_green_btn1 dark">
                    <input
                        type="submit"
                        value="Submit"
                        className="wpcf7-form-control has-spinner wpcf7-submit"
                    />

                    <span className="content">
                        <span>Submit</span>
                        <svg width="12" height="19" viewBox="0 0 12 19" fill="none">
                            <path d="M10 9.5L10.6866 10.227L11.4564 9.5L10.6866 8.77299L10 9.5ZM0.313376 17.273C-0.0881418 17.6522 -0.106225 18.2851 0.272987 18.6866C0.652199 19.0881 1.28511 19.1062 1.68662 18.727L0.313376 17.273ZM0.313376 1.72701L9.31338 10.227L10.6866 8.77299L1.68662 0.272987L0.313376 1.72701ZM9.31338 8.77299L0.313376 17.273L1.68662 18.727L10.6866 10.227L9.31338 8.77299Z"
                                  fill="#202631">

                            </path>
                        </svg>
                    </span>
                    <span className="border top"></span>
                    <span className="border right"></span>
                    <span className="border bottom"></span>
                    <span className="border left"></span>

                    <span className="wpcf7-spinner"></span>
                </div>
                {isSendForm.status ? (
                    <span className={isSendForm.status}>{isSendForm.message}</span>
                ) : null}
            </div>
        </form>
    );
};

FutureForm.propTypes = {
    formId: PropTypes.number.isRequired,
};

export default FutureForm;
