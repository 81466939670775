import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

export const SimpleButton = ({ button, primary = true, onClick }) => {
  const primaryBtn = primary ? "link_button" : "link_button secondary";

  const event = (e) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <a
      className={primaryBtn}
      href={button.url}
      target={button.target || "_self"}
      onClick={event}
    >
      {button.title}
    </a>
  );
};

SimpleButton.propTypes = {
  button: PropTypes.shape({
    url: PropTypes.string.isRequired,
    target: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
};

SimpleButton.defaultProps = {
  primary: true,
  onClick: null,
};
