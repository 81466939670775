import React from "react";
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import useOpenContactModal from "src/hooks/useOpenContactModal";

import Button from "src/components/button";
import Repeater from "src/components/page/BannerSection/repeater";
import "./style.css";

const BannerSection = (props) => {
  const bannerImage = getImage(props.bannerBigImage.localFile);

  const [_, toggleModal] = useOpenContactModal();
  const openModal = () => toggleModal(true);
  return (
    <section className="banner_section">
      <div className="banner_main">
        <div className="banner_big_images_box">
          <div className="left_img">
            <StaticImage
              src="../../../images/300.jpg"
              alt="bg"
              loading="eager"
              placeholder="none"
            />
          </div>
          <div className="right_img">
            <GatsbyImage
              alt={props.bannerBigImage.altText}
              image={bannerImage}
              loading="eager"
              imgClassName="team_image"
              placeholder="none"
            />
            <StaticImage
              className="banner_triangle"
              src="../../../images/88.png"
              loading="eager"
              alt="bg"
              placeholder="none"
            />
          </div>
        </div>
        <div className="container">
          <div className="text_container">
            <h1>{parse(props.bannerTitle)}</h1>
            <p> {props.bannerText}</p>
            {props.bannerButton ? (
              <Button onClick={openModal}>{props.bannerButton.title}</Button>
            ) : null}
          </div>
          {props.showBottomLinks && props.repeater?.length ? (
            <div className="banner_bottom_links">
              {" "}
              <Repeater repeater={props.repeater} />{" "}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};
export default BannerSection;
