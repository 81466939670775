import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import "./style.css";

const PanoramaSlider = ({ slides }) => {
  return (
    <div className="panorama_slider">
      <Swiper
        loop
        centeredSlides={true}
        spaceBetween={20}
        speed={800}
        allowTouchMove={false}
        onSlideChange={() => {
          const gears = document.querySelector(".work .gears");
          gears.classList.add("animate");
          setTimeout(() => {
            gears.classList.remove("animate");
          }, 750);
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        breakpoints={{
          320: {
            slidesPerView: 1.5,
          },
          1200: {
            slidesPerView: 2,
          },
        }}
      >
        {slides.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="img_box">
              <img src={item} alt="alt" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default PanoramaSlider;
