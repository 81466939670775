import React from 'react';
import './style.css';

const BounceAnimation = ({ text, duration, direction, isInView }) => {
  const animationNameCss = direction === 'bottom-to-top' ? 'bounce-bottom-to-top' : 'bounce-top-to-bottom';

  const letters = text.split('');
  const lettersObj = letters.map((letter, index) => 
    <span 
      key={index} 
      className='letter' 
      style={{
        '--animDelay': duration / letters.length * index +'ms',
        animationName: isInView ? animationNameCss : '',
        width: letter === ' ' ? '25px' : '',
      }}>
        {letter}
    </span>
  );

  return (
    <div className={"bounce_text "+direction}>
      {lettersObj}
    </div>
  );
};

export default BounceAnimation;