import React from "react";
import Svg from "../../../../images/svg/new-svg/Corporate.svg";

const InfoItem = ({ title, text, translate, offset }) => {
  const handleMouseMove = (e) => {
    // Get parent's element position
    const parentElPos = e.target.closest(".item").getBoundingClientRect();
    const parentElTop = parentElPos.top;
    const parentElLeft = parentElPos.left;

    // Set the tooltip's position
    const tooltip = e.target.closest(".item").querySelector(".hover-txt");
    tooltip.style.top = e.clientY - parentElTop + "px";
    const leftPos = e.clientX + tooltip.offsetWidth > window.innerWidth ? tooltip.offsetWidth * -1 : 0;
    tooltip.style.left = e.clientX - parentElLeft + leftPos + "px";
  };

  const toggleAccordion = (e) => {
    if (window.innerWidth > 992) return null;

    const toggleBox = e.target.closest(".item").querySelector(".hover-txt");
    const boxHeight = toggleBox.querySelector(".text-box").offsetHeight;
    if (toggleBox.className.includes("open")) {
      toggleBox.classList.remove("open");
      toggleBox.style.height = "0px";
      return null;
    }
    toggleBox.classList.add("open");
    toggleBox.style.height = boxHeight + "px";
  };

  return (
    <div
      className="item"
      style={{
        transform: `${translate}(${translate === "translate" ? offset + "px, " + offset + "px" : offset + "px"})`,
        transition: `.0s ease`,
      }}
      onMouseMove={(e) => handleMouseMove(e)}
    >
      <div className="item-inner" onClick={(e) => toggleAccordion(e)}>
        <Svg />
        <h3>{title}</h3>
      </div>
      <div className="hover-txt">
        <div className="text-box">{text}</div>
      </div>
    </div>
  );
};

export default InfoItem;
