import React from "react";
import "./style.css";
import ScrollBannerBgSvg from "../../../images/svg/scroll-banner.svg";

const Imprint = (props) => {
    return(
        <>           
            <ImprintBanner title={props.title}/>
            <ImprintContent pageContent={props.content}/>
        </>
    )
}

const ImprintBanner = (props) => {
    return(
        <section className="imprint_banner">
            <div className="container">
                <h1 className="imprint_title">{props.title}</h1>
            </div>            
            <div className="bottom_wave">
                <ScrollBannerBgSvg />
            </div>
        </section>
    )
}

const ImprintContent = (props) => {
    return(
        <section className="imprint_content">      
            <div className="container">
                <article className="pageContent " dangerouslySetInnerHTML={{ __html: props.pageContent }}/>
            </div>  
        </section>
    )
}

export default Imprint;