import React, { useState } from "react";
import ItemDropdown from "./components/item-dropdown";

import "./style.css";
const ServicesSection = (props) => {
  const [openDropDown, setOpenDropDown] = useState(0);

  const changeDropDown = (index) => {
    const value = openDropDown === index ? null : index;
    setOpenDropDown(value);
  };
  return (
    <section className="services_section">
      <div className="container">
        <ul className="services_list">
          {Array.isArray(props.servicesRepeater)
            ? props.servicesRepeater.map((item, index) => (
                <ItemDropdown
                  key={index}
                  titleImage={item.titleImage}
                  image={item.contentImage}
                  active={openDropDown === index}
                  content={item.contentText}
                  title={item.serviceTitle}
                  onChangeDropDown={() => {
                    changeDropDown(index);
                  }}
                />
              ))
            : null}
        </ul>
      </div>
    </section>
  );
};

export default ServicesSection;
