import React from "react";
import { useInView } from "react-intersection-observer";

import "./style.css";
// import CtaButton from "../cta-button";
//import Button from "../../../cta-button";
import FutureForm from "../../../formFuture/future-form";


const FormSection = () => {
  const [ref, inView] = useInView({
    threshold: 0.35,
    triggerOnce: true,
  });

  return (
    <section className={"form-section " + (inView ? "visible" : null)} ref={ref}>
      <div className="container">
        <div className="flex-container">
          <div className="title-block">
            <h2>
              <span style={{ "--d": `0ms` }}>Start</span>
              <span style={{ "--d": `150ms` }}>getting</span>
              <span style={{ "--d": `300ms` }}>results</span>
            </h2>
            <h4>in one click</h4>
          </div>
          <div className="form-block">
            <h3>get in touch today</h3>

            <div className="form_wrapp future-form">
              <FutureForm formId={4413}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FormSection;
