import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import QuoteSvg from "../../../images/svg/quote.svg";
import SliderArrowSvg from "../../../images/svg/slider_arrow.svg";

import "./styles.css";

const TestimonialsBusinessSlider = ({ title, testimonials }) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <section className="testimonials_business_slider">
      {title && <h2>{title}</h2>}
      {testimonials && testimonials.length >= 5 && (
        <Swiper
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          pagination={{
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 3,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
              centeredSlides: true,
            },
            768: {
              slidesPerView: 1.5,
              spaceBetween: 20,
            },
            1199: {
              slidesPerView: 2.5,
              spaceBetween: 40,
            },
            1500: {
              slidesPerView: 3.5,
              spaceBetween: 62,
            },
          }}
        >
          {testimonials.map(({ review, name, logo, date, stars }, index) => (
            <SwiperSlide key={"slide" + index}>
              <div className="slide_item">
                <div className="decorate">
                  <div className="stars">
                    {stars && (
                      <div className="star_wrap">
                        {stars.map(({ star }, index) => (
                          <img
                            key={"stars" + index}
                            src={star.sourceUrl}
                            alt={star.altText || "star"}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="quote">
                    <QuoteSvg />
                  </div>
                </div>
                <div className="review">{review || "no review"}</div>
                <div className="info_wrap">
                  <div className="logo">
                    <img src={logo?.sourceUrl} alt={logo?.altText || "logo"} />
                  </div>
                  <div className="info">
                    {name && <div className="name">{name}</div>}
                    {date && <div className="date">{date}</div>}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="controll_block">
            <div className="swiper-button-prev" ref={navigationPrevRef}>
              <SliderArrowSvg />
            </div>
            <div className="swiper-pagination"></div>
            <div className="swiper-button-next" ref={navigationNextRef}>
              <SliderArrowSvg />
            </div>
          </div>
        </Swiper>
      )}
    </section>
  );
};

export default TestimonialsBusinessSlider;
