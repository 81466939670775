import * as React from "react";
import {Suspense, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import MainBanner from "../components/page/AnimationSections/MainBanner";
import InformationSection from "../components/page/AnimationSections/InformationSection";
import MissionSection from "../components/page/AnimationSections/MissionSection";
import WorkSection from "../components/page/AnimationSections/WorkSection";
import FormSection from "../components/page/AnimationSections/FormSection";
import Scrollbar from "smooth-scrollbar";
import CustomCursor from "../components/customCursor";
import ContactModalNew from "../components/modal/contact-modal/contact-modal-new";
import {CSSTransition} from "react-transition-group";
const duration = 300;

const transitionStyles = {
    entering: { left: 0 },
    entered: { left: 0 },
    exiting: { left: "100%" },
    exited: { left: "100%" },
};

const TestPage = () => {

    const [isOpen, setOpenModal] = useState(false);
    //const [isBrowser, setBrowser] = useState(false);

   /* useEffect(() => {
        if(typeof window !== 'undefined') {
            setBrowser(true);
        }
    }, [])*/

    useEffect(() => {
        const header = document.querySelector("#gatsby-focus-wrapper header");
        const footer = document.querySelector("#gatsby-focus-wrapper footer");
        const main = document.querySelector("main.main");

        if (window.innerWidth > 600) {
            footer.classList.add("fixed");
            main.style.marginBottom = footer.offsetHeight + "px";
        }

        const scrollerContainer = document.querySelector("#gatsby-focus-wrapper");
        const scroller = Scrollbar.init(scrollerContainer);
        scroller.addListener(function (status) {
            let offset = status.offset;
            document.documentElement.style.setProperty("--posY", offset.y + "px");
            header.style.transform = `translateY(${offset.y}px)`;
            header.classList.remove("fixed");
            if (offset.y > 0) header.classList.add("fixed");

            footer.style.top = window.innerWidth > 600 ? offset.y + (window.innerHeight - footer.offsetHeight) + "px" : 0;
        });
    }, []);
    const zIndex = isOpen ? {zIndex: 99999} : {};
    return (
        <>
            <div>
                {!isOpen ? <CustomCursor/> : null}
            </div>

            <main className="main" style={zIndex}>
                <MainBanner openModal={() => setOpenModal(true)}></MainBanner>
                <InformationSection></InformationSection>
                <MissionSection></MissionSection>
                <WorkSection></WorkSection>
                <FormSection/>
            </main>
            <div style={{position: 'fixed'}}>


                { typeof window !== "undefined" ?
                    ReactDOM.createPortal(<CSSTransition in={isOpen} timeout={duration} mountOnEnter key="modal">
                        {(state) => (
                            <div style={transitionStyles[state]} className="contact_modal_animation">
                                <Suspense>
                                    <ContactModalNew
                                        eventClose={() => setOpenModal(false)}
                                    />
                                </Suspense>
                            </div>
                        )}
                    </CSSTransition>, document.querySelector('body'))
                    : null }

            </div>
        </>
    );
};

export default TestPage;

