import React from "react";
import './style.css';
import {StaticImage} from "gatsby-plugin-image";
import CardMember from "./card-member";


import useResize from "../../../hooks/useResize";
import TeamSlider from "../TeamMembersSection/index.jsx";



const TeamSection = (props) => {
    const [slideShowMobile] = useResize(767);
    if (slideShowMobile === false) {
        return <TeamSlider {...props}></TeamSlider>
    }
    return (
        <section className="team-member">
            <div className="container">
                <div className="triangles_box">
                    <StaticImage
                        src="../../../images/triangles_86.jpg"
                        alt="triangles_86"
                    />
                </div>
                <h2>{props.sectionTitle}</h2>
                <div className="grid-box">
                    {props.teamSlider.map((item, index) => (
                        <CardMember
                            position={item.teamMemPos}
                            image={item.teamImg}
                            name={item.teamMemName}
                            key={index}
                            index={index}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
};

export default TeamSection;
