import React from "react";
import { graphql } from "gatsby";
import SubscribeForm from "../components/subscribe-form";
import BannerPost from "../components/banner-posts";
import LastedFromBlog from "../components/latest-from-blog";
import Pagination from "../components/pagination";

const BlogIndex = ({ data, pageContext }) => {
  const bannerPosts = data.allWpPost.nodes.slice(0, 4);
  const allPosts = data.allWpPost.nodes.slice(4);
  return (
    <>
      <div className="main-wrapper">
        <BannerPost posts={bannerPosts} />
        {allPosts.length ? <LastedFromBlog posts={allPosts} /> : null}
        <Pagination
          currentPage={pageContext.page}
          totalPage={pageContext.totalPages}
          postsPerPage={pageContext.postsPerPage}
        />
      </div>
      <SubscribeForm />
    </>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        featuredImage {
          node {
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        categories {
          nodes {
            name
            id
          }
        }
        databaseId
      }
    }
  }
`;
