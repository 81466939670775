// See .cache/page-templates after running dev/build
// to understand how this file ends up looking

import React from "react";
import { graphql } from "gatsby";
import { useContext } from "react";
import Seo, { SEOContext } from "gatsby-plugin-wpgraphql-seo";

import Imprint from '../../src/components/page/Imprint'

const PageTemplate = (pageProps) => {
  const data = useContext(SEOContext);
  let components;

    components = pageProps.data.wpPage['flexible']['mainFlexibleContent'] || []
  
  components = components.map((component) => {
    return {
      name: component.__typename.split("_").pop(),
      data: component,
    };
  });

  console.log("TEST");
  return (
    <>
      <Seo post={Object.assign(data, pageProps.data.wpPage)} />
      {components.map((component, index) => {

      if (component.name == 'Imprint') {
        return <Imprint {...component.data} key={index} />
      }
    
        return (
          <div key={index}>
            Error: The component {component.name} was not found
          </div>
        );
      })}
    </>
  );
};

export default PageTemplate;


    export const query = graphql`
      query PageQuery4828($id: String!) {
        wpPage(id: {eq: $id}) {
          nodeType
          title
          uri
          seo {
            breadcrumbs {
              text
              url
            }
            canonical
            cornerstone
            focuskw
            fullHead
            metaDesc
            metaKeywords
            metaRobotsNofollow
            metaRobotsNoindex
            opengraphDescription
            opengraphAuthor
            opengraphImage {
              altText
              sourceUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
            readingTime
            schema {
              articleType
              pageType
              raw
            }
            title
            twitterDescription
            twitterTitle
        }
          
    flexible {
      mainFlexibleContent {
        __typename
         
 
      ... on WpPage_Flexible_MainFlexibleContent_Imprint {
        ...Page_Imprint
      }
    
      }
    }
  
        }
      }
    `
  
