import React, { lazy, useEffect, Suspense } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import { CSSTransition } from "react-transition-group";
import { useLocation } from "@reach/router";

import useSwiperLazy from "src/hooks/useSwiperLazy";
import useOpenContactModal from "src/hooks/useOpenContactModal";
import Header from "src/components/header";
import HeaderNew from "src/components/header-new";
import Footer from "src/components/footer";

const ContactModal = lazy(() => import("src/components/modal/contact-modal"));
import "../styles/global.css";
const duration = 300;

const transitionStyles = {
  entering: { left: 0 },
  entered: { left: 0 },
  exiting: { left: "100%" },
  exited: { left: "100%" },
};

const sendPageView = (pathname) => {
  if (typeof window?.gtag === "function") {
    window.gtag("event", "View Page", {
      event_category: "Category Page",
      event_label: `${pathname}`,
    });
  }
};
const Index = ({ children, path, location }) => {
  console.log(location);
  const { pathname } = useLocation();

  useEffect(() => {
    sendPageView(pathname);
  }, [pathname]);

  const [isOpen] = useOpenContactModal();
  const [_, loadSwiper] = useSwiperLazy();
  useEffect(() => {
    const scrollSetLoadSwiper = () => {
      loadSwiper(true);
      document.removeEventListener("scroll", scrollSetLoadSwiper);
    };
    setTimeout(() => {
      document.addEventListener("scroll", scrollSetLoadSwiper, false);
    }, 2000);
  }, []);
  const {
    wp: { seo },
    // allWpPage: { nodes: pages },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
      allWpPage {
        nodes {
          id
          slug
          title
        }
      }
    }
  `);
  return (
    <>
      <SEOContext.Provider value={{ global: seo }}>
        {!location.pathname.includes("on-the-future-edge") &&
        !location.pathname.includes("wptheme") ? (
          <Header path={path} />
        ) : (
          <HeaderNew />
        )}
        {children}
        <CSSTransition in={isOpen} timeout={duration} mountOnEnter key="modal">
          {(state) => (
            <div
              style={transitionStyles[state]}
              className="contact_modal_animation"
            >
              <Suspense>
                <ContactModal />{" "}
              </Suspense>
            </div>
          )}
        </CSSTransition>
        {path !== "/wptheme/" && (
          <Footer isGatsbyLink={path !== "/on-the-future-edge/"} />
        )}
      </SEOContext.Provider>
    </>
  );
};

export default Index;
