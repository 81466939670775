import React, { useState, useEffect } from "react";
import InfoItem from "./item";
import { useInView } from "react-intersection-observer";
import Scrollbar from "smooth-scrollbar";

const ItemList = [
  {
    title: "MEDICAL PROJECTS",
    icon: "",
    text:"Make hard to explain things easy and native. Manage complex processes painless in a safe, properly tested way.",
    translate: "translate",
    direction: -1,
  },
  {
    title: "E-COMMERCE",
    icon: "",
    text: "Are you a happy web shop owner? Elevate your business logic with a fast, user-friendly website featuring eye-catching design.",
    translate: "translateX",
    direction: 1,
  },
  {
    title: "REAL ESTATE PROJECTS",
    icon: "",
    text: "It what with which we began and never stop develop. Let's materialize your ideas into breakthrough decisions with our unwavering experience.",
    translate: "translate",
    direction: 1,
  },
  {
    title: "SAAS",
    icon: "",
    text: "Do you look to make people's lives easier? we are doing it as well, let's cooperate :)",
    translate: "translate",
    direction: -1,
  },
  {
    title: "CORPORATE  WEBSITES",
    icon: "",
    text: "Creating a web presence seems easy nowadays, but turning a good website into something perfect and distinct is challenging. That's where we excel – in achieving perfection and standing out from the rest.",
    translate: "translateY",
    direction: -1,
  },
  {
    title: "EDUCATION PROJECTS",
    icon: "",
    text: "You are enlight people and it's a great, maybe the most important thing in the World. However, to make a difference, people need to find you. That's where our highly optimized websites for SEO come in, ensuring your impact reaches far and wide.",
    translate: "translateX",
    direction: 1,
  },
];

const itemsList = () => {
  // Moving on scroll animation
  const itemsLength = ItemList.length;
  const [itemsPositions, setItemsPositions] = useState(() => {
    const startPositions = [];
    for (let i = 0; i < itemsLength; i++) {
      startPositions.push({
        offset: 0,
        step: Math.floor(Math.random() * (Math.floor(12) - Math.ceil(7) + 1)) + Math.ceil(7),
      });
    }
    return startPositions;
  });

  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  function updatePositions(scroll) {
    const currentPositions = itemsPositions.map((item, index) => {
      item.offset = window.innerWidth > 991 ? (scroll / item.step) * ItemList[index].direction : 0;
      return item;
    });

    setItemsPositions(currentPositions);
  }

  useEffect(() => {
    if (inView1) {
      const scroller = Scrollbar.getAll()[0];
      scroller.addListener(function () {
        const containerPos = document.querySelector(".info-section .info-items").getBoundingClientRect();
        const containerTop = (containerPos.top - window.innerHeight) * -1;
        updatePositions(containerTop);
      });
    }
  }, [inView1]);

  return (
    <div className="info-items" ref={ref1}>
      {ItemList.map((item, index) => (
        <InfoItem
          key={index}
          title={item.title}
          icon={item.icon}
          text={item.text}
          translate={item.translate}
          offset={itemsPositions[index].offset}
        />
      ))}
    </div>
  );
};

export default itemsList;
