import React from "react";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import ContactForm from "src/components/form/contact-form";

import "./style.css";
const ContactSection = (props) => {
  return (
    <section className="contact_section">
      <div className="contact_main">
        <div className="contact_bg_img">
          <StaticImage
            src="../../../images/top-triangle-113.png"
            alt="top-triangle"
            className="form_top_triangle"
            imgClassName="form_top_triangle"
          />
          <GatsbyImage
            image={getImage(props.image.localFile)}
            alt={props.image.altText}
            className="form_bg_img form_bg_img-absolute"
            imgClassName="form_bg_img"
          />
          <StaticImage
            src="../../../images/left-bottom-triangle-90.png"
            alt="left-bottom-triangle"
            className="form_bottom_triangle bottom-image"
          />
        </div>
        <div className="container">
          <div className="text_form_wrapp">
            <h2>{parse(props.sectionTitle)}</h2>
            <p className="form_text">{parse(props.sectionText)}</p>
            <div className="form_wrapp">
              <ContactForm formId={3205}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
