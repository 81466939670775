import React from "react";

import ArrowRight from "src/images/svg/new-svg/arrow-right.svg";

import "./style.css";

const Button = ({ onClick = () => {}, children, type = "button", color = "" }) => {
  const event = (e) => {
    e.preventDefault();
    onClick();
  };
  return (
    <button type={type} className={`cta_button ${color}`} onClick={event}>
      <span className="content">
        <span>{children}</span>
        <ArrowRight />
      </span>
      <span className="border top"></span>
      <span className="border right"></span>
      <span className="border bottom"></span>
      <span className="border left"></span>
    </button>
  );
};

export default Button;
