import React from "react";

import { SimpleButton } from "../../buttons/simple_button";
import MainDecorSvg from "src/images/svg/bg_square_decor.svg";
import DotsSvg from "src/images/svg/group_dots.svg";
import RadiusArrowSvg from "src/images/svg/radius_arrow.svg";

import "./styles.css";

const LandingBanner = (props) => {
  const { title, titleColorPart, ctaButton, image, description } = props;

  return (
    <section className="landing_banner">
      <div className="wrapper">
        <div className="bg_decor">
          <MainDecorSvg />
        </div>
        <div className="dots_decor">
          <DotsSvg />
        </div>
        <div className="left_part">
          {title && (
            <h1>
              {title}
              {titleColorPart && <span> {titleColorPart}</span>}
            </h1>
          )}
          {description && <div className="description">{description}</div>}
          {ctaButton && (
            <div className="btn_decor">
              <div className="arrow_wrap">
                <RadiusArrowSvg />
              </div>
              <div className="btn_wrap">
                <SimpleButton button={ctaButton} />
              </div>
            </div>
          )}
        </div>
        <div className="right_part">
          <div className="img_wrap">
            <img src={image?.sourceUrl} alt={image?.altText || "image"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingBanner;
