import React from "react";
import PropTypes from 'prop-types';
import { Link } from "gatsby";

const Repeater = ({ repeater }) => {
  return (
    <>
      {repeater?.map(({ bannerSmallImage, bannerSmallBtn }, index) => {
        return (
          <div className="box" key={index}>
            <div className="banner_small_img_wrapp">
              <img
                width="70px"
                height="70px"
                src={bannerSmallImage.sourceUrl}
                alt={bannerSmallBtn.title}
              />
            </div>
            <Link to='/our-services/' state={{scrollTo: bannerSmallBtn.url?.replace('#', '')}} className="small_button">
              {bannerSmallBtn.title}
            </Link>
          </div>
        );
      })}
    </>
  );
};
export default Repeater;

Repeater.propTypes = {
  repeater: PropTypes.array.isRequired
}
