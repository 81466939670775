import React from "react";
import "./style.css";

const AwardsSection = (props) => {
    return(
        <section className="awards_section">
            <div className="container">
                <h2 className="awards_title">{props.title}</h2>
                <div className="awards_area">
                    {props.awardsImages.map((image, index) => (
                        <div className="award_item"  key={index}>
                            <img                               
                                src={image.sourceUrl}
                                alt={`7Fridays Award`}
                                className="award_image"
                            />
                        </div>                        
                    ))}
                </div>
            </div>            
        </section>
    )
}

export default AwardsSection;