// See .cache/page-templates after running dev/build
// to understand how this file ends up looking

import React from "react";
import { graphql } from "gatsby";
import { useContext } from "react";
import Seo, { SEOContext } from "gatsby-plugin-wpgraphql-seo";

import BusinessCardsWithBg from '../../src/components/page/BusinessCardsWithBg'
import BusinessProcessCards from '../../src/components/page/BusinessProcessCards'
import ImagesWithDescriptionOdd from '../../src/components/page/ImagesWithDescriptionOdd'
import LandingBanner from '../../src/components/page/LandingBanner'
import LandingFooter from '../../src/components/page/LandingFooter'
import TestimonialsBusinessSlider from '../../src/components/page/TestimonialsBusinessSlider'

const PageTemplate = (pageProps) => {
  const data = useContext(SEOContext);
  let components;

    components = pageProps.data.wpPage['flexible']['mainFlexibleContent'] || []
  
  components = components.map((component) => {
    return {
      name: component.__typename.split("_").pop(),
      data: component,
    };
  });

  console.log("TEST");
  return (
    <>
      <Seo post={Object.assign(data, pageProps.data.wpPage)} />
      {components.map((component, index) => {

      if (component.name == 'BusinessCardsWithBg') {
        return <BusinessCardsWithBg {...component.data} key={index} />
      }
    

      if (component.name == 'BusinessProcessCards') {
        return <BusinessProcessCards {...component.data} key={index} />
      }
    

      if (component.name == 'ImagesWithDescriptionOdd') {
        return <ImagesWithDescriptionOdd {...component.data} key={index} />
      }
    

      if (component.name == 'LandingBanner') {
        return <LandingBanner {...component.data} key={index} />
      }
    

      if (component.name == 'LandingFooter') {
        return <LandingFooter {...component.data} key={index} />
      }
    

      if (component.name == 'TestimonialsBusinessSlider') {
        return <TestimonialsBusinessSlider {...component.data} key={index} />
      }
    
        return (
          <div key={index}>
            Error: The component {component.name} was not found
          </div>
        );
      })}
    </>
  );
};

export default PageTemplate;


    export const query = graphql`
      query PageQuery4437($id: String!) {
        wpPage(id: {eq: $id}) {
          nodeType
          title
          uri
          seo {
            breadcrumbs {
              text
              url
            }
            canonical
            cornerstone
            focuskw
            fullHead
            metaDesc
            metaKeywords
            metaRobotsNofollow
            metaRobotsNoindex
            opengraphDescription
            opengraphAuthor
            opengraphImage {
              altText
              sourceUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
            readingTime
            schema {
              articleType
              pageType
              raw
            }
            title
            twitterDescription
            twitterTitle
        }
          
    flexible {
      mainFlexibleContent {
        __typename
         
 
      ... on WpPage_Flexible_MainFlexibleContent_BusinessCardsWithBg {
        ...Page_BusinessCardsWithBg
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_BusinessProcessCards {
        ...Page_BusinessProcessCards
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_ImagesWithDescriptionOdd {
        ...Page_ImagesWithDescriptionOdd
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_LandingBanner {
        ...Page_LandingBanner
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_LandingFooter {
        ...Page_LandingFooter
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_TestimonialsBusinessSlider {
        ...Page_TestimonialsBusinessSlider
      }
    
      }
    }
  
        }
      }
    `
  
