import React, { lazy } from "react";

import { InView } from "react-intersection-observer";
import useSwiperLazy from "src/hooks/useSwiperLazy";

const TeamSlider = lazy(() =>
  import("src/components/page/TeamMembersSection/team-slider")
);

const TeamMembersSection = (props) => {
  const [isLoadSwiper, loadSwiper] = useSwiperLazy();

  return (
    <InView
      onChange={loadSwiper}
      triggerOnce={true}
      threshold={0}
      rootMargin="25%"
    >
      {isLoadSwiper ? <TeamSlider {...props} /> : null}
    </InView>
  );
};

export default TeamMembersSection;
