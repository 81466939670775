import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as css from "src/styles/modules/components/button.module.scss";
const Button = ({ onClick ,children }) => {

  const event = (e) => {
    e.preventDefault();
    onClick();
  }
  return (
    <button className={classNames(css.btn, css.btnColorMain, 'tabs_btn standart_green_btn')} onClick={event}>
      <span className={css.btnHover}></span>
      <span className={css.btnLabel}>{children}</span>
    </button>
  );
};
export default Button;
Button.propTypes = {
  onClick: PropTypes.func.isRequired,
}
