import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import useOpenContactModal from "../hooks/useOpenContactModal";
const OrderProp = ({ title, text }) => {
  const [_, toggleModal] = useOpenContactModal();

  const openModal = (e) => {
    e.preventDefault();
    toggleModal();
  };
  return (
    <div className="order_prop">
      <p>{parse(text || '')}</p>
      <button
        className="order_green_btn standart_green_btn"
        onClick={openModal}
      >
        <span>{parse(title || '')}</span>
      </button>
    </div>
  );
};
OrderProp.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  text: PropTypes.string,
};
OrderProp.defaultProps = {
  text: ` We propose you a website with a custom solution don't hesitate to
      contact us`,
  title: `Order Proposal`,
  url: "#",
};
export default OrderProp;
