import React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const CardMember = ({name , image , position}) => {
    return (

            <div className='item'>
                <div className="img-wrap">
                    <GatsbyImage
                        imgClassName="team_img"
                        className="team_block"
                        image={getImage(image.localFile)}
                        alt={name}
                    />
                </div>
                <div className="team_name_pos">
                    <span className="team_mem_name">{name}</span>
                    <span className="team_mem_pos">{position}</span>
                </div>
            </div>

    )
}


export default CardMember;
