import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import useOpenContactModal from "src/hooks/useOpenContactModal";
import SolutionLinks from "src/components/solution-links";
import TheEmail from "src/components/the-email";
import FooterLogoSvg from "src/images/svg/footer-logo.svg";
import "./style.css";
const Footer = ({isGatsbyLink}) => {
  const [_, toggleModal] = useOpenContactModal();
  const { footerMenu } = useStaticQuery(graphql`
    query FooterMenu {
      footerMenu: allWpMenuItem(filter: { locations: { eq: FOOTER_MENU } }) {
        nodes {
          uri
          label
        }
      }
    }
  `);


  const openModal = (e) => {
    e.preventDefault();
    toggleModal(true);
  };
  return (
    <footer>
      <div className="container">
        <div className="footer_logo_box">
          <Link to={"/"}>
            <FooterLogoSvg />
          </Link>
        </div>
        <div className="footer_menu_wrapp">
          <div className="menu-footer-menu-container">
            <ul>
              {footerMenu.nodes.map(({ uri, label }) => {
                return (
                    <li key={uri}>
                      { isGatsbyLink ? <Link to={uri}>{label}</Link> : <a href={uri} onClick={(e) => {
                        e.preventDefault();
                        window.location.href = uri
                      }}>{label}</a>}
                    </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className="footer_solutions_wrapp">
          <h3>Solutions</h3>
          <div className="footer_repeater_box">
            <SolutionLinks/>
          </div>
        </div>
        <div className="footer_contact_wrapp">
          <div className="footer_phone">
            <TheEmail />
          </div>
          <div className="footer_contact_btn">
            <button
              onClick={openModal}
              className="green_contact_btn"
            >
              <span>Contact Us</span>
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
