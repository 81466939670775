import React, {Fragment} from "react";
import {graphql, Link} from "gatsby";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import SubscribeForm from "../components/subscribe-form";
import parse from "html-react-parser";
import FeaturedImage from "../components/banner-posts/featured-image";
import Share from "../components/share";
import BreadcrumbsRouter from "../components/breadcrumbs-router";
import SvgBlogWave from "../images/svg/blog-wave.svg";
import SvgBtnPost from "../images/svg/btn-post.svg";
import LastedFromBlog from "src/components/latest-from-blog";
import useResize from 'src/hooks/useResize';
import "./single-blog.css";

const HOME_LINK = "/";

const BlogPostTemplate = ({data}) => {
    const [desktop] = useResize(768);
    return (
        <Fragment>
            <div className="main-wrapper">
                <section className="single-post-section">
                    <div className="bg">
                        <SvgBlogWave></SvgBlogWave>
                    </div>
                    <div className="container">
                        <BreadcrumbsRouter
                            homeLink={HOME_LINK}
                            title={data.post?.title}
                        ></BreadcrumbsRouter>
                        <div className="single-post-wrapper">
                            <div className="banner">
                                <FeaturedImage
                                    lazy={false}
                                    image={data.post?.featuredImage?.node?.localFile}
                                />
                            </div>
                            <div className="wrap-sub-banner">
                                <div className="data">
                                    <StaticImage
                                        src="../../images/calendar-gray.png"
                                        alt="bg"
                                        loading="eager"
                                    />
                                    <span>{parse(data.post?.date || "")}</span>
                                </div>
                                <div className="share">
                                    <Share bgStyle={{fill: 'rgba(255, 255, 255, 0.2)'}}></Share>
                                </div>
                            </div>
                            <div className="wrapper">
                                <h1 className="post-title">{data.post?.title}</h1>
                                <div className="content">{parse(data.post?.content || "")}</div>
                                <hr/>
                                <Share></Share>
                            </div>
                        </div>
                        <div className="prev-next-articles">
                            {data.previous ? (
                                <div className="left-box">
                                    <Link className="box" to={data.previous.uri}>
                                        {data.previous?.featuredImage?.node?.localFile ? (
                                            <GatsbyImage
                                                image={getImage(
                                                    data.previous?.featuredImage.node.localFile
                                                )}
                                                alt="article image"
                                                loading="eager"
                                            />
                                        ) : (
                                            <StaticImage
                                                src="../../images/Rectangle-10.jpg"
                                                alt="article image"
                                                loading="eager"
                                            />
                                        )}

                                        <SvgBtnPost></SvgBtnPost>
                                    </Link>
                                    <h3>{parse(data.previous.title || "")}</h3>
                                </div>
                            ) : null}
                            {data.next ? (
                                <div className="right-box">
                                    <h3>{parse(data.next.title || "")}</h3>
                                    <Link className="box" to={data.next.uri}>
                                        {data.next?.featuredImage?.node?.localFile ? (
                                            <GatsbyImage
                                                image={getImage(
                                                    data.next?.featuredImage.node.localFile
                                                )}
                                                alt="article image"
                                                loading="eager"
                                            />
                                        ) : (
                                            <StaticImage
                                                src="../../images/Rectangle-10.jpg"
                                                alt="article image"
                                                loading="eager"
                                            />
                                        )}
                                        <SvgBtnPost></SvgBtnPost>
                                    </Link>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </section>
                <section className="related-articles">
                    <div className="container">
                        <h2>Related Articles</h2>
                        <div className="line"></div>
                        {Array.isArray(data?.post?.related_posts?.nodes) ? (
                            <LastedFromBlog
                                style={desktop ? undefined : {margin: "40px 0"}}
                                posts={data.post.related_posts.nodes}
                                showTitle={false}
                            />
                        ) : null}
                    </div>
                </section>
                <SubscribeForm/>
            </div>
        </Fragment>
    );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      title
      categories {
        nodes {
          name
          id
        }
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 50)
            }
          }
        }
      }
      related_posts {
        nodes {
          title
          uri
          databaseId
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 50)
                }
              }
            }
          }
          categories {
            nodes {
              name
              id
            }
          }
        }
      }
      content
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 50)
            }
          }
        }
      }
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 50)
            }
          }
        }
      }
    }
  }
`;
