import React from "react";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

import TwoImageSectionBg from "../../../images/svg/two-image-section-bg.svg";
import TwoImageSectionBg2 from "../../../images/svg/two-image-section-bg2.svg";
import "./style.css";

const TwoImageSection = (props) => {
  return (
    <section className="two_image_section ">
      <div className="container">
        <h2>{parse(props.sectionTitle)}</h2>
        <div className="two_img_content">
          <div className="two_img_bg">
            <TwoImageSectionBg />
            <TwoImageSectionBg2 />
          </div>
          <div className="two_img_wrapp">
            <div className="left_img_box">
              <GatsbyImage
                alt={props.leftImage.altText}
                image={getImage(props.leftImage.localFile)}
                loading="eager"
                imgClassName="team_image"
              />
            </div>
            <div className="right_img_box">
              <GatsbyImage
                alt={props.rightImage.altText}
                image={getImage(props.rightImage.localFile)}
                loading="eager"
                imgClassName="team_image"
              />
            </div>
          </div>
          <div className="text_part_wrapp">
            <p>{parse(props.sectionText)}</p>

            {props?.twoImgButton ? (
              <Link
                className="two_img_btn standart_green_btn"
                to={props.twoImgButton?.url}
              >
                <span>{props.twoImgButton?.title}</span>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};
export default TwoImageSection;
