import React from "react";
import parse from "html-react-parser";

import BottomDecorateSvg from "../../../images/svg/bottom_decorate.svg";
import { SimpleButton } from "../../buttons/simple_button";
import useOpenContactModal from "src/hooks/useOpenContactModal";

import "./styles.css";

const ImagesWithDescriptionOdd = (props) => {
  const { title, list, sectionBgColor, button } = props;
  const [_, toggleModal] = useOpenContactModal();

  const openModal = () => toggleModal(true);

  return (
    <section
      className="images_with_description_odd"
      style={{ backgroundColor: sectionBgColor }}
    >
      <div className="container_landing">
        {title && <h2>{title}</h2>}
        {list && list.length > 0 && (
          <div className="items_wrapper">
            {list.map((item, idx) => (
              <div key={"item" + idx} className="item">
                <div className="img_wrap">
                  {item?.image && (
                    <img
                      className={idx === list.length - 1 && "last_img"}
                      src={item.image?.sourceUrl}
                      alt={item.image.altText || "photo"}
                    />
                  )}
                </div>
                <div className="content">
                  {item?.itemTitle && (
                    <div className="item_title">{item.itemTitle}</div>
                  )}
                  {item?.itemDescription && (
                    <div className="item_description">
                      {parse(item.itemDescription)}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {button && (
          <div className="btn_wrap">
            <SimpleButton button={button} primary={false} onClick={openModal} />
          </div>
        )}
      </div>
      <div className="section_decor">
        <BottomDecorateSvg />
      </div>
    </section>
  );
};

export default ImagesWithDescriptionOdd;
