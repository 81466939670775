import React, { lazy } from "react";
import { InView } from "react-intersection-observer";
import useSwiperLazy from "src/hooks/useSwiperLazy";
const SliderTeamWordsSection = lazy(() =>
  import("src/components/page/TeamWordsSection/slider")
);
import "./style.css";

const TeamWordsSection = (props) => {
  const [isLoadSwiper, loadSwiper] = useSwiperLazy();

  return (
    <InView
      onChange={loadSwiper}
      triggerOnce={true}
      threshold={0}
      rootMargin="25%"
    >
      {isLoadSwiper ? <SliderTeamWordsSection {...props} /> : null}
    </InView>
  );
};

export default TeamWordsSection;
