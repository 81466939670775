import React from "react";
import SvgWaves from "../../../images/svg/waves.svg";
import { CardList } from "../../cards/card-list";
import SubTitle from "../../subtitle";
import { SimpleButton } from "../../buttons/simple_button";
import useOpenContactModal from "src/hooks/useOpenContactModal";

import "./style.css";

const BusinessProcessCards = (props) => {
  const { title, subtitle, subtitleBgColor, contentBgColor, cards, button } =
    props;
  const [_, toggleModal] = useOpenContactModal();

  const openModal = () => toggleModal(true);

  return (
    <section className="business_process_cards">
      <div className="container_landing">
        {title && <h2>{title}</h2>}
        <div
          className="content_wrapper"
          style={{ backgroundColor: contentBgColor }}
        >
          {subtitle && <SubTitle title={subtitle} Bg={subtitleBgColor} />}
          <CardList cards={cards} svg={<SvgWaves />} />
          {button && (
            <div className="btn_wrap">
              <SimpleButton button={button} onClick={openModal} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default BusinessProcessCards;
