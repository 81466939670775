import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import Menu from "./menu";
import Hamburger from "./hamburger";
import BusinessHeader from "./business_header";

import LogoSvg from "src/images/svg/logo-white.svg";
import BusinessLogoSvg from "src/images/svg/business_logo.svg";

import "./style.css";

const Header = () => {
  const [menuVisibility, setMenuVisibility] = useState(false);

  const { pathname } = useLocation();

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
              if(window) {
                window.addEventListener('scroll',() => {
                console.log('end: load');
                  setTimeout(function(){
                      console.log('timeout: load')
                      const t=document.createElement('script');
                      t.src="https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GTAG_ID}";
                      document.head.appendChild(t);
                      
                      window.dataLayer = window.dataLayer || []
                      window.gtag = function gtag() { window.dataLayer.push(arguments) }
                      gtag('js', new Date())
                      gtag('config', '${process.env.GATSBY_GTAG_ID}', { send_page_view: false })
                      window.gtag("event", "View Page", {
                        event_category: "Category Page",
                        event_label: window.location.pathname,
                      });
                  }, 1000);
                }, {once: true})
              }
          `}
        </script>
      </Helmet>

      <header className="headerLanding">
        <div className="container">
          <div className="flex_wrap">
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/";
              }}
              className="logo"
            >
              {pathname === "/wptheme/" ? <BusinessLogoSvg /> : <LogoSvg />}
            </a>
            {pathname !== "/wptheme/" ? (
              <div
                className="menu_btn"
                onClick={() =>
                  setMenuVisibility(menuVisibility ? null : "active")
                }
              >
                <Hamburger menuVisibility={menuVisibility} />
              </div>
            ) : (
              <BusinessHeader />
            )}
          </div>
        </div>
        {pathname !== "/wptheme/" && <Menu menuVisibility={menuVisibility} />}
      </header>
    </>
  );
};

export default Header;
