import React from "react";

import ContactForm from "src/components/form/contact-form";
import { SimpleBanner } from "../../banners/simple-banner";
import TheEmail from "src/components/the-email";

import LogoSvg from "src/images/svg/logo-white.svg";
import GoogleSvg from "src/images/svg/google_logo.svg";
import PointSvg from "src/images/svg/point_logo.svg";

import "./styles.css";

const LandingFooter = (props) => {
  const {
    title,
    subtitle,
    bannerIcon,
    bannerTitle,
    bannerDescription,
    bannerButton,
    showBanner,
    bottomText,
  } = props;

  return (
    <section className="landing_footer">
      <div className="container">
        {title && <h2>{title}</h2>}
        {subtitle && <div className="subtitle">{subtitle}</div>}
        <div className="form_wrap">
          <ContactForm formId={3205} />
        </div>
        {showBanner === "Yes" && (
          <SimpleBanner
            icon={bannerIcon}
            title={bannerTitle}
            description={bannerDescription}
            button={bannerButton}
          />
        )}
        <div className="bottom_block">
          <div className="logo_wrap">
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/";
              }}
              className="logo"
            >
              <LogoSvg />
            </a>
          </div>
          {bottomText && <div className="bottom_text">{bottomText}</div>}
          <div className="mail_block">
            <div className="email">
              <TheEmail />
            </div>
            <div className="images">
              <a
                href="https://clutch.co/profile/7fridays#highlights"
                rel="noreferrer"
                target="_blank"
                className="email_icon_wrap"
              >
                <PointSvg />
              </a>
              <a
                href="https://www.google.com/search?q=7fridayes&sca_esv=7898575184a519fd&sca_upv=1&sxsrf=ADLYWIIQYPECSNQP1t7CUtkVzfAZUQtJjw%3A1718619780989&source=hp&ei=hA5wZpCiOqKRwPAPmZaQyA4&iflsig=AL9hbdgAAAAAZnAclMzSnlv-Sv26J9cdO2yTpVS_ipIg&udm=&ved=0ahUKEwiQ9LDMteKGAxWiCBAIHRkLBOkQ4dUDCBU&uact=5&oq=7fridayes&gs_lp=Egdnd3Mtd2l6Igk3ZnJpZGF5ZXMyBxAjGLACGCcyBxAAGIAEGA0yDRAuGIAEGMcBGA0YrwEyBhAAGA0YHjIGEAAYDRgeMgYQABgNGB4yBhAAGA0YHjIGEAAYDRgeMgYQABgNGB4yBhAAGA0YHki-F1AAWL0UcAB4AJABAJgBbKAB3waqAQM2LjO4AQPIAQD4AQGYAgmgAv0GwgIEECMYJ8ICChAjGIAEGCcYigXCAgsQABiABBixAxiDAcICCBAAGIAEGLEDwgIFEAAYgATCAgsQLhiABBjHARivAcICBxAAGIAEGArCAgcQLhiABBgKwgIEEAAYHsICBhAAGAoYHsICCBAAGIAEGMsBwgIOEC4YgAQYxwEYywEYrwHCAgoQABiABBgKGMsBmAMA4gMFEgExIECSBwM1LjSgB6Rj&sclient=gws-wiz#lrd=0x80c299cc75e3bb81:0xed27e24d6211b160,1,,,,"
                rel="noreferrer"
                target="_blank"
                className="email_icon_wrap"
              >
                <GoogleSvg />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingFooter;
