import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Scrollbar from "smooth-scrollbar";

import "./style.css";

const MissionSection = () => {
  const circles = 3;
  const radius = 25; // Adjust the radius as needed
  const [itemsPositions, setItemsPositions] = useState([
    { x: 0, y: 0 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
  ]);
  const floatingTime = [3100, 3500, 2700];

  const updatePositions = () => {
    let newPositions = [];
    for (let i = 0; i < circles; i++) {
      const x = Math.random() * (2 * radius) - radius;
      const y = Math.random() * (2 * radius) - radius;
      newPositions.push({ x, y });
    }
    setItemsPositions(newPositions);
  };

  useEffect(() => {
    if (window.innerWidth > 1200) {
      const stickyInner = document.querySelector(".mission .mission-inner");
      stickyInner.closest(".mission-wrapper").style.height = stickyInner.offsetHeight + window.innerWidth * 0.55 + "px";
    }

    for (let i = 0; i < circles; i++) {
      const intervalId = setInterval(() => {
        updatePositions();
      }, floatingTime[i]); // Adjust the interval as needed

      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  const [ref, inView] = useInView({
    threshold: 0.1,
  });
  const [ref1, inView1] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView && window.innerWidth > 1200) {
      const scroller = Scrollbar.getAll()[0];
      scroller.addListener(function () {
        const container = document.querySelector(".mission-wrapper");
        const containerSticky = document.querySelector(".mission-inner");
        const containerPos = container.getBoundingClientRect();
        const containerTop = containerPos.top;
        const scroll = containerTop * -1 + window.innerHeight - containerSticky.offsetHeight;

        const transitionDot = document.querySelector(".mission .transitionDot");
        if (scroll > 0) transitionDot.style.opacity = 1;
        const dotHeight = scroll / ((window.innerWidth / 1920) * 6 + 1);
        transitionDot.style.height = scroll > 0 ? dotHeight + "vw" : "18px";

        const dotWidth = (dotHeight - 80) * 2;
        transitionDot.style.width = dotHeight > 80 ? dotWidth + "vw" : "18px";
      });
    }
  }, [inView]);

  // console.log(inView);
  return (
    <section className="mission" ref={ref}>
      <div className="mission-wrapper">
        <div className={"mission-inner " + (inView1 ? "visible" : null)}>
          <div className="mission-circles-wrap">
            <div
              className="mission-circle mission-circle-1"
              style={{
                transform: `translate(${itemsPositions[0].x}px, ${itemsPositions[0].y}px)`,
                transition: `${floatingTime[0] + 1000}ms cubic-bezier(0.18, 0.89, 0.32, 1.28)`,
              }}
            ></div>
            <div
              className="mission-circle mission-circle-2"
              style={{
                transform: `translate(${itemsPositions[1].x}px, ${itemsPositions[1].y}px)`,
                transition: `${floatingTime[1] + 1000}ms cubic-bezier(0.18, 0.89, 0.32, 1.28)`,
              }}
            ></div>
            <div
              className="mission-circle mission-circle-3"
              style={{
                transform: `translate(${itemsPositions[2].x}px, ${itemsPositions[2].y}px)`,
                transition: `${floatingTime[2] + 1000}ms cubic-bezier(0.18, 0.89, 0.32, 1.28)`,
              }}
            ></div>
          </div>

          <div className="container">
            <article ref={ref1}>
              <div className="title_box">
                <h2>
                  <span>Mission</span>
                  <span className="dot_box">
                    <span className="dot"></span>
                    <span className="transitionDot"></span>
                  </span>
                </h2>
              </div>
              <p>
                {"We specialize in aiding individuals in accomplishing unique endeavors, turning dreams into reality, or furnishing them with impeccably developed tools for their daily tasks. We have a proven process that ensures you get the results you want. If you believe in your idea, let's see if we're the right team to make it happen."}{" "}
              </p>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionSection;
