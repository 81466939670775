function mainBammerCanvasAnim(canvas, PlanetIcon) {
  canvas.width = canvas.getBoundingClientRect().width;
  canvas.height = canvas.getBoundingClientRect().height;
  let ctx = canvas.getContext("2d");

  let xCenter = canvas.width / 2;
  let yCenter = canvas.height / 2;

  let sphereRadius = 500;
  let pointRadius = 18;
  let pointsNum = 250;
  let depth = sphereRadius * 2;

  let pointsArr = [];

  let base_image = new Image();
  base_image.src = PlanetIcon;
  let alpha = 0.2;

  let mouse = {
    x: 0,
    y: 0,
    mouseRadius: 180,
  };

  let color = "#285656";

  let theta = 0.004;
  let acc = false;

  function init() {
    for (let i = 0; i < pointsNum; i++) {
      pointsArr.push(new Point());
    }

    canvas.addEventListener("mousedown", function () {
      acc = true;
    });

    canvas.addEventListener("mouseup", function () {
      acc = false;
    });

    canvas.addEventListener("mousemove", function (event) {
      let offsetX = window.innerWidth - canvas.width * 0.7;
      mouse.x = event.clientX - offsetX;
      mouse.y = event.clientY;
    });

    animate();
  }

  //animate
  function animate() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawImage();

    if (acc && theta < 0.4) {
      if (sphereRadius <= 700) {
        sphereRadius += 5;
        depth = sphereRadius * 2;
        alpha += 0.025;
      }
      theta += 0.004;
    } else if (!acc && theta > 0.004) {
      theta -= 0.002;
      if (sphereRadius > 500) {
        sphereRadius -= 5;
        depth = sphereRadius * 2;
        if (alpha > 0.2) {
          alpha -= 0.025;
        }
      }
    }

    pointsArr.sort((point1, point2) => {
      return point1.scale - point2.scale;
    });

    for (let i = 0; i < pointsNum; i++) {
      pointsArr[i].update(ctx);
    }

    window.requestAnimationFrame(animate);
  }

  function drawImage() {
    ctx.globalAlpha = alpha;
    ctx.drawImage(base_image, xCenter - 107, yCenter - 107, 215, 215);
    ctx.globalAlpha = 1;
  }

  function getRandomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }

  function map(inputNum, minInput, maxInput, minOutput, maxOutput) {
    return ((inputNum - minInput) * (maxOutput - minOutput)) / (maxInput - minInput) + minOutput;
  }

  function getDistance(a, b) {
    return Math.sqrt(Math.pow(a.x - b.xOr, 2) + Math.pow(a.y - b.yOr, 2));
  }

  class Point {
    constructor() {
      this.theta = getRandomNumber(0, Math.PI * 2);
      this.pi = getRandomNumber(0, Math.PI);

      this.x = 0;
      this.y = 0;
      this.z = 0;

      this.scale = 0;
      this.xOr = 0;
      this.yOr = 0;

      this.opacity = 1;
      this.radius = pointRadius;
      this.color = color;

      this.velocity = {
        x: 0,
        y: 0,
      };

      this.mass = this.radius * 0.002;
    }

    update(ctx) {
      this.x = sphereRadius * Math.sin(this.pi) * Math.cos(this.theta);
      this.y = sphereRadius * Math.cos(this.pi);
      this.z = sphereRadius * Math.sin(this.pi) * Math.sin(this.theta) + sphereRadius;

      this.scale = depth / (depth + this.z);

      this.xOr = this.x * this.scale + xCenter;
      this.yOr = this.y * this.scale + yCenter;

      this.radius = map(this.z, 0, depth, pointRadius, 0);
      this.opacity = map(this.z, 0, depth, 1, 0);
      this.theta += theta;

      let acceleration = {
        x: 0,
        y: 0,
      };

      let delta = {
        x: mouse.x - this.xOr,
        y: mouse.y - this.yOr,
      };

      let distance = getDistance(mouse, this) || 1;
      let force = ((distance - mouse.mouseRadius) / distance) * this.mass;

      // console.log(force);
      if (distance < mouse.mouseRadius) {
        force = (distance - mouse.mouseRadius) * this.mass;
        let alpha = mouse.mouseRadius / distance / 2;
        this.color = `rgba(44,196,134, ${alpha})`;
      } else {
        this.color = "#285656";
        force = this.mass;
      }

      acceleration.x += delta.x * force;
      acceleration.y += delta.y * force;

      this.velocity.x = this.velocity.x * 0.93 + acceleration.x * this.mass;
      this.velocity.y = this.velocity.y * 0.93 + acceleration.y * this.mass;

      this.draw(ctx);
    }

    draw(ctx) {
      ctx.globalAlpha = this.opacity;
      ctx.fillStyle = this.color;
      ctx.beginPath();

      this.xOr = this.xOr + this.velocity.x;
      this.yOr = this.yOr + this.velocity.y;

      ctx.arc(this.xOr, this.yOr, Math.abs(this.radius), 0, Math.PI * 2, true);
      ctx.fill();
    }
  }

  init();
}

export default mainBammerCanvasAnim;
