import React from 'react';
//import {StaticImage} from "gatsby-plugin-image";
import parse from 'html-react-parser';
import Item from "./item";

import CircleLogo from './../../../images/svg/circle-logo.svg';
import Logo from './../../../images/svg/circle-logo-7f.svg';

import './slyle.css';

const TabsSection = (props) => {
  /*const [tabActive, setTabActive] = useState(0);
  const changeTabActive = value => setTabActive(value);*/

  return (
        <section className="tabs_section">
            <div className="container">
                <div className="wrapper">
                    <div className="side_img1">
                        <div className="wrap-circle">
                            <div className="wrap-circle-second">
                                <div className="large-circle"></div>
                                <div className="middle-circle"></div>
                            </div>
                        </div>
                        <div className="small-circle">
                            <Logo/>
                            <div className="circle-logo">
                                <CircleLogo/>
                            </div>
                        </div>
                    </div>
                    <h2>{parse(props.sectionTitle)}</h2>
                    <div className="blocks_wrap">
                        {props.tabsRepeater.map((tab, index) => (
                            <Item
                                image={tab.tabsImage}
                                title={tab.tabTitle}
                                key={index}
                                id={tab.tabButton}
                                content={tab.tabMainText}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>

  );
};
export default TabsSection;
