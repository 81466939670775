import React from "react";

import GlobalSvg from "src/images/svg/global.svg";

import "./styles.css";

const PartnersList = (props) => {
  const { title, partnerList } = props;

  return (
    <section className="partners">
      <div className="container">
        <h2>{title}</h2>
        {partnerList.length > 0 && (
          <div className="partner_list">
            {partnerList.map(({ logo, name, comment, link }, idx) => (
              <div className="partner_item" key={"p" + idx}>
                <div className="icon_wrap">
                  {logo && (
                    <img src={logo.sourceUrl} alt={logo.altText || "logo"} />
                  )}
                </div>
                <div className="content_wrap">
                  <div className="top_part">
                    {name && <h4>{name}</h4>}
                    {link && (
                      <a
                        href={link?.url || "#"}
                        target={link?.target || "_self"}
                        rel="noreferrer"
                      >
                        {link?.title}
                        <GlobalSvg />
                      </a>
                    )}
                  </div>
                  {comment && <p>{comment}</p>}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default PartnersList;
