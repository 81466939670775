import React from 'react';
import parse from 'html-react-parser';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

import Dropdown from 'src/components/page/CareerSection/dropdown';
import './style.css';

const CareerSection = (props) => {
  return (
    <section className="career_section">
      <div className="container">
        {props.sectionTitle ? <h2>{parse(props.sectionTitle)}</h2> : null}
        <div className="career_text_img_box">
          <div className="career_text">
            <p>{parse(props.sectionText)}</p>
          </div>
          <GatsbyImage
            className="career_img"
            image={getImage(props.careerImage.localFile)}
            alt={props.careerImage.altText}
          />
          <h3>WE are looking for:</h3>
        </div>
        <div className="career_list">
          {Array.isArray(props.careerRepeater)
            ? props.careerRepeater.map((item, index) => (
              <Dropdown
                key={index}
                text={item.careerItemText}
                title={item.careerTitle}
              />
            ))
            : null}
        </div>
      </div>
    </section>
  );
};

export default CareerSection;
