import React from "react";
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton} from "react-share"
import {FacebookIcon, TwitterIcon, LinkedinIcon} from "react-share";
import './style.css';

const OPTION_ICON = {
    borderRadius: 5,
    size: 32,
    iconFillColor: 'var(--hover_color)'
}


const Share = ({children, bgStyle = { fill: "#eef0f5" } }) => {
    const styledIcon = {
        ...OPTION_ICON,
        bgStyle: bgStyle,
    }
    return (
        <div className="share-wrapper">
            {children ? children : <span>Share</span>}
            <FacebookShareButton url={'https://www.7fridays.net/'}><FacebookIcon {...styledIcon}/></FacebookShareButton>
            <LinkedinShareButton url={'https://www.7fridays.net/'}><LinkedinIcon {...styledIcon}/></LinkedinShareButton>
            <TwitterShareButton url={'https://www.7fridays.net/'}><TwitterIcon {...styledIcon}/></TwitterShareButton>
        </div>
    )
}

export default Share;
