import React from "react";
import "./style.css";
import Post from "./post";
import SvgBlogWave from "../../images/svg/blog-wave.svg";

const BannerPost = ({ posts }) => {
  return (
    <section className="banner_news">
      <div className="bg">
        <SvgBlogWave></SvgBlogWave>
      </div>
      <div className="container">
        <div className="text_wrap">
          <h1>Be in touch with 7Fridays</h1>
        </div>
        <div className="posts">
          {posts.map(
            ({ title, databaseId, date, featuredImage, uri, categories }) => (
              <Post
                key={databaseId}
                title={title}
                date={date}
                featuredImage={featuredImage?.node.localFile}
                uri={uri}
                categories={categories.nodes}
              />
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default BannerPost;
