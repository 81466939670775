import React from "react";

import { CardItem } from "./card-item/card-item";

import "./styles.css";

export const CardList = ({ cards, svg }) => {
  return (
    <div className="cards_list">
      {Array.isArray(cards) &&
        cards.length > 0 &&
        cards.map((card, indx) => (
          <CardItem key={"card" + indx} card={card} svg={svg} />
        ))}
    </div>
  );
};
