// See .cache/page-templates after running dev/build
// to understand how this file ends up looking

import React from "react";
import { graphql } from "gatsby";
import { useContext } from "react";
import Seo, { SEOContext } from "gatsby-plugin-wpgraphql-seo";

import AwardsSection from '../../src/components/page/AwardsSection'
import BannerSection from '../../src/components/page/BannerSection'
import BestWorkSection from '../../src/components/page/BestWorkSection'
import ContactSection from '../../src/components/page/ContactSection'
import TabsSection from '../../src/components/page/TabsSection'
import TeamWordsSection from '../../src/components/page/TeamWordsSection'
import TwoImageSection from '../../src/components/page/TwoImageSection'
import WorkWithSection from '../../src/components/page/WorkWithSection'

const PageTemplate = (pageProps) => {
  const data = useContext(SEOContext);
  let components;

    components = pageProps.data.wpPage['flexible']['mainFlexibleContent'] || []
  
  components = components.map((component) => {
    return {
      name: component.__typename.split("_").pop(),
      data: component,
    };
  });

  console.log("TEST");
  return (
    <>
      <Seo post={Object.assign(data, pageProps.data.wpPage)} />
      {components.map((component, index) => {

      if (component.name == 'AwardsSection') {
        return <AwardsSection {...component.data} key={index} />
      }
    

      if (component.name == 'BannerSection') {
        return <BannerSection {...component.data} key={index} />
      }
    

      if (component.name == 'BestWorkSection') {
        return <BestWorkSection {...component.data} key={index} />
      }
    

      if (component.name == 'ContactSection') {
        return <ContactSection {...component.data} key={index} />
      }
    

      if (component.name == 'TabsSection') {
        return <TabsSection {...component.data} key={index} />
      }
    

      if (component.name == 'TeamWordsSection') {
        return <TeamWordsSection {...component.data} key={index} />
      }
    

      if (component.name == 'TwoImageSection') {
        return <TwoImageSection {...component.data} key={index} />
      }
    

      if (component.name == 'WorkWithSection') {
        return <WorkWithSection {...component.data} key={index} />
      }
    
        return (
          <div key={index}>
            Error: The component {component.name} was not found
          </div>
        );
      })}
    </>
  );
};

export default PageTemplate;


    export const query = graphql`
      query PageQuery6($id: String!) {
        wpPage(id: {eq: $id}) {
          nodeType
          title
          uri
          seo {
            breadcrumbs {
              text
              url
            }
            canonical
            cornerstone
            focuskw
            fullHead
            metaDesc
            metaKeywords
            metaRobotsNofollow
            metaRobotsNoindex
            opengraphDescription
            opengraphAuthor
            opengraphImage {
              altText
              sourceUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
            readingTime
            schema {
              articleType
              pageType
              raw
            }
            title
            twitterDescription
            twitterTitle
        }
          
    flexible {
      mainFlexibleContent {
        __typename
         
 
      ... on WpPage_Flexible_MainFlexibleContent_AwardsSection {
        ...Page_AwardsSection
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_BannerSection {
        ...Page_BannerSection
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_BestWorkSection {
        ...Page_BestWorkSection
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_ContactSection {
        ...Page_ContactSection
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_TabsSection {
        ...Page_TabsSection
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_TeamWordsSection {
        ...Page_TeamWordsSection
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_TwoImageSection {
        ...Page_TwoImageSection
      }
     
 
      ... on WpPage_Flexible_MainFlexibleContent_WorkWithSection {
        ...Page_WorkWithSection
      }
    
      }
    }
  
        }
      }
    `
  
