import React from "react";
import PhoneSvg from "../images/svg/email.svg";
import { graphql, useStaticQuery } from "gatsby";

const TheEmail = () => {
  const {
    wpPage: { contactSite },
  } = useStaticQuery(graphql`
    query ContactEmailSite {
      wpPage(isFrontPage: { eq: true }) {
        contactSite {
          companyEmail
        }
      }
    }
  `);
  return (
    <>
      <PhoneSvg></PhoneSvg>
      {contactSite?.companyEmail ? (
        <a href={`mailto:${contactSite.companyEmail}`} className="email_link">
          {contactSite.companyEmail}
        </a>
      ) : null}
    </>
  );
};

export default TheEmail
