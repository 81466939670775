import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import classNames from "classnames";
import ThemeContext from "src/context/theme-context";
import AnimateHeight from "react-animate-height";
import HeaderMenu from "src/components/header/header-menu";
import TheEmail from "src/components/the-email";
import AnimatedUnderlineMenu from "src/components/header/animated-underline-menu";
import Sticky from "react-sticky-el";
import { Helmet } from "react-helmet";

import LogoSvg from "src/images/svg/logo.svg";
import CloseSvg from "src/images/svg/close.svg";

import "./style.css";

const Header = ({ path }) => {
  const theme = useContext(ThemeContext);
  const headerRef = useRef(null);
  const [isOpeMobileMenu, setOpenMobileMenu] = useState(false);
  const [pathNamePage, setPathNamePage] = useState(false);
  useEffect(() => {
    if (path == "/on-the-future-edge") {
      setPathNamePage(true);
    }
  }, []);

  const toggleMobileMenu = () => {
    setOpenMobileMenu(!isOpeMobileMenu);
  };
  useEffect(() => {
    theme.dispatch({
      type: "headerHeight",
      payload: headerRef.current?.offsetHeight,
    });
  }, [headerRef]);

  useEffect(() => {
    setOpenMobileMenu(false);
  }, [path]);

  const toggleContactModal = (e) => {
    e.preventDefault();
    theme.dispatch({
      type: "contactModal",
      payload: true,
    });
  };
  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
             if(window) {
                window.addEventListener('scroll',() => {
                console.log('end: load');
                  setTimeout(function(){
                      console.log('timeout: load')
                      const t=document.createElement('script');
                      t.src="https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GTAG_ID}";
                      document.head.appendChild(t);
                      
                      window.dataLayer = window.dataLayer || []
                      window.gtag = function gtag() { window.dataLayer.push(arguments) }
                      gtag('js', new Date())
                      gtag('config', '${process.env.GATSBY_GTAG_ID}', { send_page_view: false })
                      window.gtag("event", "View Page", {
                        event_category: "Category Page",
                        event_label: window.location.pathname,
                      });
                  }, 1000);
                }, {once: true})
              }
          `}
        </script>
      </Helmet>
      <header
        ref={headerRef}
        className={classNames("headerDefault", { show: !pathNamePage })}
      >
        <div className="fixing">
          <div className="header_top_bar">
            <div className="container">
              <TheEmail />
            </div>
          </div>
          <Sticky
            stickyStyle={{
              zIndex: 999,
              backgroundColor: "#fff",
              boxShadow: "0 0 10px rgb(11 45 62 / 10%)",
            }}
          >
            <div className="container-fluid-custom container">
              <div className="logo floatLeft">
                <Link to={"/"} className="header_logo-item">
                  <LogoSvg />
                </Link>
              </div>
              <nav className="header-menu-desktop">
                <AnimatedUnderlineMenu />
              </nav>
              <nav className="header-menu-mobile">
                <AnimateHeight
                  duration={500}
                  id="animation-height-header"
                  height={isOpeMobileMenu ? "auto" : 0}
                >
                  <HeaderMenu />
                </AnimateHeight>
              </nav>
              <div className="header_contact_btn">
                <button
                  className="green_contact_btn"
                  onClick={toggleContactModal}
                  style={{ cursor: "pointer " }}
                >
                  <span>Contact Us</span>
                </button>
              </div>
              <div
                className="mobileMenu"
                onClick={toggleMobileMenu}
                role="presentation"
              >
                <CloseSvg
                  className={classNames("ham hamRotate ham4", {
                    active: isOpeMobileMenu,
                  })}
                />
              </div>
            </div>
          </Sticky>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  path: PropTypes.string.isRequired,
};
export default Header;
