import React  from "react";
import ArrowTabSvg from "../../../images/svg/icon.svg";
import { Link } from "gatsby";

const Item = ({image, id , title , content}) => {


    if (typeof window !== "undefined") {
        localStorage.setItem('loading', 'true');
    }

    return(
        <div className="item">
            <Link to="/our-solutions/" state={{id: id}} >
                <div className="arrow_icon">
                    <ArrowTabSvg />
                </div>
                <div className="block_wrap">
                    <h3 className="block_title">{title}</h3>
                    <div className="block_icon">
                        <img src={image.sourceUrl}  alt={image.altText} />
                    </div>
                    <div className="block_description">{content}</div>
                </div>
            </Link>
        </div>
    )
}

export default Item;