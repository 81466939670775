import React from "react";

import "./styles.css";

const SubTitle = ({ title, Bg }) => {
  return (
    <div className="subtitle" style={{ backgroundColor: Bg }}>
      {title}
    </div>
  );
};

export default SubTitle;
