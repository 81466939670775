import React, {useEffect, useRef, useContext} from "react";
import ThemeContext from "../../../../context/theme-context";
import parse from "html-react-parser";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import AnimateHeight from "react-animate-height";
import OrderProp from "../../../order-prop";
import {useLocation} from '@reach/router';
import scrollToElement from "scroll-to-element";


const ItemDropdown = ({
  image,
  content,
  title,
  titleImage,
  active,
  onChangeDropDown = () => {},
}) => {
  const themeContext = useContext(ThemeContext);
  const location = useLocation();
  const refSrolll = useRef(null);
  useEffect(()=>{
    if(title?.toLowerCase()===location?.state?.scrollTo?.toLowerCase()){
      if(!active){
        changeDropDown();
      }
      setTimeout(()=>{
        scrollToElement(refSrolll.current, {
          offset: themeContext.state.headerHeight * -1,
          duration: 800,
        });
      },500)
    }
  },[]);

  const changeDropDown = () => {
    onChangeDropDown();
  };
  return (
    <li ref={refSrolll} className={classNames("services_item", { active })}>
      <div className="service_title">
        <div className="title_img">
          <img
            className="title_image"
            src={titleImage.sourceUrl}
            alt={titleImage.altText}
          />
        </div>
        <h3 onClick={changeDropDown}>
          <span>{parse(title)}</span>
          <span className="title_line"></span>
          <div className="plus_box">
            <div className="plus"></div>
            <div className="minus"></div>
          </div>
        </h3>
      </div>
      <AnimateHeight duration={500} height={active ? "auto" : 0}>
        <div className="service_content">
          <div className="text_img_wrapp">
            <div className="text_wrapp">{parse(content)}</div>
            <div className="img_wrapp">
              <GatsbyImage
                image={getImage(image.localFile)}
                alt={image.altText}
              />
            </div>
          </div>
          <OrderProp />
        </div>
      </AnimateHeight>
    </li>
  );
};

export default ItemDropdown;
