import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import useOpenContactModal from "src/hooks/useOpenContactModal";

import "./styles.css";

const BusinessHeader = () => {
  const { button } = useStaticQuery(graphql`
    query MenuHeaderBusiness {
      button: allWpMenuItem(filter: { locations: { eq: GATSBY_HEADER_MENU } }) {
        edges {
          node {
            label
            uri
          }
        }
      }
    }
  `);

  // window.location.href = ctaBtn?.uri || "#";

  const [_, toggleModal] = useOpenContactModal();

  const openModal = () => toggleModal(true);

  const ctaBtn = button.edges[0].node;

  return (
    <div className="business_header_Btn">
      <Link
        to={ctaBtn?.uri || "#"}
        onClick={(e) => {
          e.preventDefault();
          openModal();
          window.location.href = "#";
        }}
      >
        {ctaBtn?.label}
      </Link>
    </div>
  );
};

export default BusinessHeader;
