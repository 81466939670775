import React from "react";

import "./hamburger.css";

const Hamburger = (visibility) => {
  return (
    <div className={"hamburger_btn " + visibility.menuVisibility}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Hamburger;
