import React from "react";

import { SimpleButton } from "../../buttons/simple_button";
import useOpenContactModal from "src/hooks/useOpenContactModal";

import "./styles.css";

export const SimpleBanner = ({ icon, title, description, button, primary }) => {
  const [_, toggleModal] = useOpenContactModal();

  const openModal = () => toggleModal(true);

  return (
    <div className="simple_banner">
      {icon && (
        <div className="simple_banner__iconWrap">
          <img src={icon?.sourceUrl} alt={icon?.altText || "icon"} />
        </div>
      )}
      {title && <div className="simple_banner__title">{title}</div>}
      {description && (
        <div className="simple_banner__description">{description}</div>
      )}
      {button && (
        <div className="simple_banner__btn_wrap">
          <SimpleButton button={button} primary={primary} onClick={openModal} />
        </div>
      )}
    </div>
  );
};
